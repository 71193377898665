import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { accessTokenState, setAccessToken } from '@store/auth';

export function useAccessToken() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(accessTokenState);

  return {
    token,
    setToken: (token: string | null) => dispatch(setAccessToken(token)),
  };
}
