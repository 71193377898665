import { Alert, Col, Modal, Typography } from 'antd';
import { useDeleteCustomer } from '@api/customers/useDeleteCustomer';
import React, { useEffect } from 'react';
import { useGetCustomers } from '@api/customers';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  isDeleteCustomerModalOpenState,
  selectedCustomerIdState,
  setSelectedCustomerId,
} from '@store/customers';


export function DeleteCustomerModal() {
  const dispatch = useAppDispatch();
  const id = useAppSelector(selectedCustomerIdState);
  const open = useAppSelector(isDeleteCustomerModalOpenState);
  const customers = useGetCustomers();

  const { mutate: deleteCustomer, isSuccess, reset, error } = useDeleteCustomer();

  useEffect(() => {
    if (isSuccess) {
      reset();
      dispatch(setSelectedCustomerId(''));
      dispatch(closeModal('deleteCustomer'));
      customers.refetch();
    }
  }, [isSuccess]);

  const onOk = () => {
    deleteCustomer(id);
  };

  const onCancel = () => {
    reset();
    dispatch(setSelectedCustomerId(''));
    dispatch(closeModal('deleteCustomer'));
  };

  return (
    <Modal title="Elimina Cliente" open={open} onOk={onOk} onCancel={onCancel}>
      <Typography>Sei sicuro di voler eliminare il cliente?</Typography>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
