import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Commission } from '@api/types';

async function getCommission(id: string): Promise<Commission> {
  const response = await axios.get<Commission>(`${apiBaseUrl}/${ApiPath.Commissions}/${id}`);
  return response.data;
}

export function useGetCommission(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetCommission, id],
    queryFn: () => getCommission(id),
    enabled: id !== '',
  });
}
