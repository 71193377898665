import { Avatar, Button, Col, Form, Input, notification, Row, Typography } from 'antd';
import { useGetProfile, useUpdateUser } from '@api/UsersApi';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { UserProfileResponse } from '@api/types';

export function ProfileTab() {
  const [api, contextHolder] = notification.useNotification();
  const { data, refetch, isFetched, isRefetching } = useGetProfile();
  const { mutate: updateUser, isSuccess: isSuccessUpdateUser, reset: resetUpdateUser } = useUpdateUser();
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState<Partial<UserProfileResponse>>({});

  const openNotification = () => {
    api.info({
      message: `Dati aggiornati con successo`,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (isFetched && !isRefetching) {
      setInitialValues({
        username: data?.username,
        name: data?.name,
        surname: data?.surname,
        email: data?.email,
        phone: data?.phone,
      });
      setShowForm(true);
    }
  }, [isFetched, isRefetching]);

  const onFinish = (values: any) => {
    updateUser({ id: data?.id, ...values });
  };

  useEffect(() => {
    if (isSuccessUpdateUser) {
      refetch();
      openNotification();
      resetUpdateUser();
    }
  }, [isSuccessUpdateUser]);

  return (
    <Col>
      <Row justify="start" align="middle" style={{ marginBottom: 48 }}>
        <Avatar
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64 }}
          icon={<AntDesignOutlined />}
        />
        <Col>
          <Typography.Title style={{ margin: 0, marginLeft: 12 }}>{data?.name} {data?.surname}</Typography.Title>
        </Col>
      </Row>

      <Col>
        {showForm && (
          <Form
            id="login_form"
            name="login_form"
            layout="vertical"
            onFinish={onFinish}
            style={{ minWidth: 420 }}
            initialValues={initialValues}
          >
            <Row justify="center">
              <Col span={12}>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Per favore inserisci un username valido' }]}
                  label="Username"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  label="Nome"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder=""
                  />
                </Form.Item>
                <Form.Item
                  name="surname"
                  label="Cognome"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder=""
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder=""
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Telefono"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder=""
                  />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                  Salva
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Col>
      {contextHolder}
    </Col>
  );
}
