import { Breadcrumb, Button, Card, Col, Divider, Form, Input, Row, Space, Table, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useGetProduct } from '@api/products';
import { ColumnProps } from 'antd/es/table';
import React from 'react';
import { ProductOption } from '@api/types';

export function EditProduct() {
  const params = useParams();

  const { data: product, isLoading: isLoadingGetProduct } = useGetProduct(params?.id ?? '');

  const onEdit = (option: ProductOption) => {
  };

  const onDelete = (option: ProductOption) => {
  };

  const columns: ColumnProps<ProductOption>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Chiave',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  const items = [
    { title: <Link to="/registry/products">Prodotti</Link> },
    { title: product?.description ?? '' },
  ];

  if (isLoadingGetProduct) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}>
          <Form layout="vertical">
            <Form.Item name="product-description" label="Descrizione" initialValue={product?.description ?? ''}>
              <Input />
            </Form.Item>
          </Form>

          <Divider />

          <Typography.Title level={3}>Opzioni</Typography.Title>

          <Table
            columns={columns}
            dataSource={product?.options}
            expandable={{
              expandedRowRender: (record) => (
                <Col style={{ margin: '0 16px' }}>
                  <Typography.Title level={4}>Parametri</Typography.Title>
                  <Table
                    columns={[
                      { title: 'Nome', dataIndex: 'name', key: 'name' },
                      { title: 'Tipo', dataIndex: 'type', key: 'type' },
                      { title: 'Chiave', dataIndex: 'key', key: 'key' },
                    ]}
                    dataSource={record.params}
                  />
                </Col>
              ),
              rowExpandable: (record) => record.params.length > 0,
            }}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: product?.options.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />

          <Divider />

          <Typography.Title level={3}>Materiali</Typography.Title>
        </Card>
      </Col>
    </Row>
  );
}
