import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Customer } from '@api/types';


async function updateCustomer(customer: Customer): Promise<void> {
  try {
    await axios.put(`${apiBaseUrl}/${ApiPath.Customers}/${customer.id}`, customer);
  } catch (error) {
    console.error('[updateCustomer]', error);
  }
}

export function useUpdateCustomer() {
  return useMutation({
    mutationKey: [QueryKey.UpdateCustomer],
    mutationFn: updateCustomer,
  });
}
