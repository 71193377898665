import React from 'react';
import { Button, Space, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useGetSuppliers } from '@api/suppliers/useGetSuppliers';
import { Supplier } from '@api/types';
import { useAppDispatch } from '@hooks/redux';
import { openModal, setSelectedSupplier } from '@store/suppliers';

export function SuppliersTable() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetSuppliers();

  const onEdit = (supplier: Supplier) => {
    dispatch(setSelectedSupplier(supplier));
    navigate(`/registry/suppliers/${supplier.id}`);
  };

  const onDelete = (supplier: Supplier) => {
    dispatch(setSelectedSupplier(supplier));
    dispatch(openModal('deleteSupplier'));
  };

  const columns: ColumnProps<Supplier>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: data?.length ?? 0,
        }}
        rowKey="id"
        size="small"
      />
    </Spin>
  );
}
