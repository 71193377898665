import { Card, Col, Row } from 'antd';

export function Notifications() {
  return (
    <Row>
      <Col span={24}>
        <Card title="Notifiche"></Card>
      </Col>
    </Row>
  )
}
