import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface CustomersModals {
  deleteCustomer: boolean;
}

interface CustomersState {
  modals: CustomersModals;
  selectedCustomerId: string;
}

const initialState: CustomersState = {
  modals: {
    deleteCustomer: false,
  },
  selectedCustomerId: '',
};


export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },

    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },

    setSelectedCustomerId: (state, action: PayloadAction<string>) => {
      state.selectedCustomerId = action.payload;
    },
  },
});

export const { openModal, closeModal, setSelectedCustomerId } = customersSlice.actions;

export const isDeleteCustomerModalOpenState = (state: RootState) => state.customers.modals.deleteCustomer;

export const selectedCustomerIdState = (state: RootState) => state.customers.selectedCustomerId;

export default customersSlice.reducer;
