import { UserProfileResponse, UserRole } from '@api/types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface UserState {
  roles: UserRole[];
  profile: UserProfileResponse | null;
}

const initialState: UserState = {
  roles: [],
  profile: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.roles = action.payload;
    },
    setProfile: (state, action: PayloadAction<UserProfileResponse>) => {
      state.profile = action.payload;
    },
  },
});

export const { setRoles, setProfile } = userSlice.actions;

export const userRolesState = (state: RootState) => state.user.roles;
export const userProfileState = (state: RootState) => state.user.profile;

export default userSlice.reducer;
