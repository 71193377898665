import { Breadcrumb, Button, Card, Col, Row, Space, Spin, Table } from 'antd';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetIncomingOrder } from '@api/orders';
import { ColumnProps } from 'antd/es/table';
import { IncomingMaterial } from '@api/types';
import { EditIncomingMaterial } from '@routes/orders/components/EditIncomingMaterial';
import { useAppDispatch } from '@hooks/redux';
import { openModal, setSelectedIncomingMaterial } from '@store/orders';
import { useReceiveOrder } from '@api/orders/useReceiveOrder';

export function CheckOrder() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const { data: order, isLoading: isLoadingOrder } = useGetIncomingOrder(params?.id ?? '');

  const { mutate, isSuccess, reset } = useReceiveOrder();

  const onReceive = () => {
    mutate(order?.id ?? '');
  };

  const onEdit = (material: IncomingMaterial) => {
    dispatch(setSelectedIncomingMaterial(material));
    dispatch(openModal('editIncomingMaterial'));
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      navigate(`/incoming-orders`);
    }
  }, [isSuccess]);

  const columns: ColumnProps<IncomingMaterial>[] = [
    {
      title: 'Codice',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantità Ordinata',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Quantità Arrivata',
      dataIndex: 'arrivedQuantity',
      key: 'arrivedQuantity',
      render: (_, record) => record.arrivedQuantity === null ? record.quantity : record.arrivedQuantity,
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  const items = [
    { title: <Link to="/incoming-orders">Ordini in Arrivo</Link> },
    { title: `OFABL${order?.year}-${order?.serial}` },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={
            <Button type="primary" onClick={onReceive}>
              Conferma Arrivo
            </Button>
          }
        >
          <Spin spinning={isLoadingOrder}>
            <Table
              columns={columns}
              dataSource={order?.materials}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: order?.materials.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>
        </Card>
      </Col>
      <EditIncomingMaterial />
    </Row>
  );
}
