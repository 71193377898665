import { Alert, Col, Modal, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useDeleteQuotationSource, useGetQuotationSources } from '@api/quotations';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isDeleteSourceModalOpenState, selectedSourceState, setSelectedSource } from '@store/sources';


export function DeleteSourceModal() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isDeleteSourceModalOpenState);
  const selectedSource = useAppSelector(selectedSourceState);

  const { refetch: refetchQuotationSources } = useGetQuotationSources();
  const { mutate: deleteQuotationSource, isSuccess, error, reset } = useDeleteQuotationSource();

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationSources().then(() => {
        reset();
        dispatch(setSelectedSource(null));
        dispatch(closeModal('deleteSource'));
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    if (selectedSource) {
      deleteQuotationSource(selectedSource.id);
    }
  };

  const onCancel = () => {
    reset();
    dispatch(setSelectedSource(null));
    dispatch(closeModal('deleteSource'));
  };

  return (
    <Modal title="Elimina Provenienza" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Typography>Sei sicuro di voler eliminare la provenienza?</Typography>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
