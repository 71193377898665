import { Button, Col, Input, Row, Space, Table, TableColumnProps, Typography } from 'antd';
import { useGetCommissions } from '@api/commissions';
import { getCommissionStatusLabel } from '../utils';
import { Link, useNavigate } from 'react-router-dom';
import { Commission } from '@api/types';
import React, { useState } from 'react';

export function CommissionsTable() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const { data: commissions } = useGetCommissions(searchValue);

  const onEdit = (commission: Commission) => {
    navigate(`/commissions/${commission.id}`);
  };

  const columns: TableColumnProps<Commission>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Preventivo',
      dataIndex: 'quotation',
      key: 'quotation',
      render: (_, record) => <Link onClick={e => e.stopPropagation()} to={`/quotes/${record.quotation.id}`}>{record.quotation.year}-{record.quotation.serial}</Link>,
    },
    {
      title: 'Cliente',
      key: 'customer',
      render: (_, record) => record.quotation.customer?.name ?? ''
    },
    {
      title: 'Riferimento',
      key: 'reference',
      render: (_, record) => record.quotation.reference
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Typography>{getCommissionStatusLabel(text)}</Typography>,
    },
  ];

  return (
    <>
      <Row justify="end" style={{ marginTop: '24px', marginBottom: '36px' }}>
        <Col>
          <Space size="middle">
            <Input.Search
              placeholder="cerca"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              enterButton
              style={{ minWidth: '300px' }}
            />
            <Button onClick={() => setSearchValue('')}>Reset</Button>
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={commissions}
        onRow={(row) => {
          return {
            onClick: () => onEdit(row),
            style: { cursor: 'pointer' },
          };
        }}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: commissions?.length ?? 0,
        }}
        rowKey="id"
        size="small"
      />
    </>
  );
}
