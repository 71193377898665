import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Product } from '@api/types';


function updateQuotationProduct(product: Partial<Product>) {
  return axios.put(`${apiBaseUrl}/quotation-products/${product.id}`, product);
}

export const useUpdateQuotationProduct = () => useMutation({
  mutationFn: updateQuotationProduct,
  mutationKey: [QueryKey.UpdateQuotationProduct],
});
