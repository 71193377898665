import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface WarehouseModals {
  addMaterial: boolean;
  loadingMaterial: boolean;
}

interface WarehouseState {
  modals: WarehouseModals;
  selectedWarehouseId: string;
}

const initialState: WarehouseState = {
  modals: {
    addMaterial: false,
    loadingMaterial: false,
  },
  selectedWarehouseId: '',
};

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },
    setSelectedWarehouseId: (state, action: PayloadAction<string>) => {
      state.selectedWarehouseId = action.payload;
    },
  },
});

export const { openModal, closeModal, setSelectedWarehouseId } = warehouseSlice.actions;

export const isAddWarehouseMaterialModalOpenState = (state: RootState) => state.warehouse.modals.addMaterial;
export const isLoadingWarehouseMaterialModalOpenState = (state: RootState) => state.warehouse.modals.loadingMaterial;

export const selectedWarehouseIdState = (state: RootState) => state.warehouse.selectedWarehouseId;

export default warehouseSlice.reducer;
