import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CustomersTable } from './CustomersTable';
import { DeleteCustomerModal } from './DeleteCustomerModal';

export function Customers() {
  const navigate = useNavigate();

  const onNew = () => {
    navigate('/registry/customers/new');
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Clienti"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Cliente</Button>}
        >
          <CustomersTable />
          <DeleteCustomerModal />
        </Card>
      </Col>
    </Row>
  );
}
