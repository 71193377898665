import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { SupplierMaterial } from '@api/types';

async function getSupplierMaterials(id: string): Promise<SupplierMaterial[]> {
  const response = await axios.get<SupplierMaterial[]>(`${apiBaseUrl}/${ApiPath.Suppliers}/${id}/materials`);
  return response.data;
}

export function useGetSupplierMaterials(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetSupplierMaterials, id],
    queryFn: () => getSupplierMaterials(id),
    enabled: id !== '',
  });
}
