import { useGetProfile } from '@api/UsersApi';
import { useGetUserRolesQuery } from '@api/roles';
import { useEffect } from 'react';
import { useAppDispatch } from '@hooks/redux';
import { setProfile, setRoles } from '@store/user';

export function useInitApp(enabled = false) {
  const dispatch = useAppDispatch();

  const profile = useGetProfile({ enabled });
  const roles = useGetUserRolesQuery(profile.data?.id ?? '');

  useEffect(() => {
    if (roles.isSuccess && Array.isArray(roles.data)) {
      dispatch(setRoles(roles.data));
    }

    if (profile.data) {
      dispatch(setProfile(profile.data));
    }
  }, [roles, profile]);
}
