import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { Referrer } from '@api/types';

async function getReferrers() {
  try {
    const result = await axios.get<Referrer[]>(`${apiBaseUrl}/${ApiPath.Referrers}`);

    return result.data;
  } catch (err) {
    console.error('[getReferrers]', err);
  }
}

export function useGetReferrers() {
  return useQuery({
    queryKey: [QueryKey.GetReferrers],
    queryFn: getReferrers,
  });
}
