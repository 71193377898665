import { Col, Modal, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isDeleteOrderModalOpenState, selectedOrderState, setSelectedOrder } from '@store/orders';
import { useDeleteOrder } from '@api/orders/useDeleteOrder';
import { useEffect } from 'react';
import { useGetOrders } from '@api/orders';

export function DeleteOrderModal() {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isDeleteOrderModalOpenState);
  const selectedOrder = useAppSelector(selectedOrderState);

  const { mutate, isSuccess, reset } = useDeleteOrder();
  const { refetch } = useGetOrders();

  const onOk = () => {
    if (selectedOrder) {
      mutate(selectedOrder.id);
    }
  };

  const onCancel = () => {
    dispatch(closeModal('deleteOrder'));
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      dispatch(setSelectedOrder(null));
      dispatch(closeModal('deleteOrder'));
    }
  }, [isSuccess]);

  return (
    <Modal title="Aggiungi Materiale" open={isOpen} onOk={onOk} onCancel={onCancel} okType="danger" okText="Rimuovi">
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Typography>Sei sicuro di voler cancellare il seguente ordine?</Typography>
        <Typography.Title
          level={5}
          style={{ textAlign: 'center' }}
        >OFABL{selectedOrder?.year}-{selectedOrder?.serial}</Typography.Title>
      </Col>
    </Modal>
  );
}
