import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useAxiosInterceptor } from '@hooks/useAxiosInterceptor';
import { MainLayout } from './layouts/MainLayout';
import { BaseLayout } from './layouts/BaseLayout';
import { useAppSelector } from '@hooks/redux';
import { isLoggedInState } from '@store/auth';
import { useInitApp } from '@hooks/useInitApp';
import {
  CheckOrder,
  CommissionRoute,
  Commissions,
  Customer,
  Customers,
  Dashboard,
  EditOrder,
  EditProduct,
  EditQuotation,
  EditUser,
  EditWorkOrder,
  IncomingOrdersRoute,
  Login,
  Logout,
  Material,
  Materials,
  Messages,
  MetalSheetQuotesRoute,
  Notifications,
  Orders,
  PriceListRoute,
  Products,
  Quotations,
  Referrers,
  Settings,
  Sources,
  Supplier,
  Suppliers,
  Warehouse,
  WorkOrders,
  WorkOrdersPhase,
} from './routes';
import useSocket from '@hooks/useSocket';
import { useAccessToken } from '@hooks/useAccessToken';
import { WorkOrderPhase } from '@routes/work-orders/WorkOrderPhase';
import { CompletedOrders } from '@routes/work-orders/CompletedOrders';
import { WorkOrderPhaseCommissionRoute } from '@routes/work-orders/WorkOrderPhaseCommission';
import { WorkersRoute } from '@routes/workers/WorkersRoute';


const privateRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: 'registry',
          children: [
            { path: 'customers', element: <Customers /> },
            { path: 'customers/:id', element: <Customer /> },
            { path: 'suppliers', element: <Suppliers /> },
            { path: 'suppliers/:id', element: <Supplier /> },
            { path: 'referrers', element: <Referrers /> },
            { path: 'sources', element: <Sources /> },
            { path: 'materials', element: <Materials /> },
            { path: 'materials/:id', element: <Material /> },
            { path: 'products', element: <Products /> },
            { path: 'products/:id', element: <EditProduct /> },
            { path: 'price-list', element: <PriceListRoute /> },
          ],
        },
        {
          path: 'quotes',
          children: [
            { index: true, element: <Quotations /> },
            { path: ':id', element: <EditQuotation /> },
          ],
        },
        {
          path: 'metal-sheet-quotes',
          children: [
            { index: true, element: <MetalSheetQuotesRoute /> },
          ],
        },
        {
          path: 'commissions',
          children: [
            { index: true, element: <Commissions /> },
            { path: ':id', element: <CommissionRoute /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { index: true, element: <Orders /> },
            { path: ':id', element: <EditOrder /> },
          ],
        },
        {
          path: 'incoming-orders',
          children: [
            { index: true, element: <IncomingOrdersRoute /> },
            { path: ':id', element: <CheckOrder /> },
          ],
        },
        {
          path: 'warehouse',
          element: <Warehouse />,
        },
        {
          path: 'work-orders',
          children: [
            { index: true, element: <WorkOrders /> },
            { path: ':id', element: <EditWorkOrder /> },
          ],
        },
        {
          path: 'completed-orders',
          children: [
            { index: true, element: <CompletedOrders /> },
          ],
        },
        {
          path: 'laser-cut',
          children: [
            { index: true, element: <WorkOrdersPhase workPhaseKey="laser-cut" /> },
            { path: ':id', element: <WorkOrderPhase workPhaseKey="laser-cut" /> },
            {
              path: ':workOrderId/:workOrderCommissionId',
              element: <WorkOrderPhaseCommissionRoute workPhaseKey="laser-cut" />,
            },
          ],
        },
        {
          path: 'folding',
          children: [
            { index: true, element: <WorkOrdersPhase workPhaseKey="folding" /> },
            { path: ':id', element: <WorkOrderPhase workPhaseKey="folding" /> },
            {
              path: ':workOrderId/:workOrderCommissionId',
              element: <WorkOrderPhaseCommissionRoute workPhaseKey="folding" />,
            },
          ],
        },
        {
          path: 'welding',
          children: [
            { index: true, element: <WorkOrdersPhase workPhaseKey="welding" /> },
            { path: ':id', element: <WorkOrderPhase workPhaseKey="welding" /> },
            {
              path: ':workOrderId/:workOrderCommissionId',
              element: <WorkOrderPhaseCommissionRoute workPhaseKey="welding" />,
            },
          ],
        },
        {
          path: 'painting',
          children: [
            { index: true, element: <WorkOrdersPhase workPhaseKey="painting" /> },
            { path: ':id', element: <WorkOrderPhase workPhaseKey="painting" /> },
            {
              path: ':workOrderId/:workOrderCommissionId',
              element: <WorkOrderPhaseCommissionRoute workPhaseKey="painting" />,
            },
          ],
        },
        {
          path: 'assembly',
          children: [
            { index: true, element: <WorkOrdersPhase workPhaseKey="assembly" /> },
            { path: ':id', element: <WorkOrderPhase workPhaseKey="assembly" /> },
            {
              path: ':workOrderId/:workOrderCommissionId',
              element: <WorkOrderPhaseCommissionRoute workPhaseKey="assembly" />,
            },
          ],
        },
        {
          path: 'workers',
          element: <WorkersRoute />,
        },
        {
          path: 'messages',
          element: <Messages />,
        },
        {
          path: 'notifications',
          element: <Notifications />,
        },
        {
          path: 'settings',
          children: [
            { index: true, element: <Settings /> },
            { path: 'users/:id', element: <EditUser /> },
          ],
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
    },
  ]);

const publicRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <BaseLayout />,
      children: [{ index: true, element: <Login /> }],
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

export const RootRouter = () => {
  const { isLoading } = useAxiosInterceptor();
  const isLoggedIn = useAppSelector(isLoggedInState);
  const { token } = useAccessToken();

  useInitApp(isLoggedIn);
  const { contextHolder } = useSocket(token);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <RouterProvider router={isLoggedIn ? privateRoutes : publicRoutes} />
      {contextHolder}
    </>
  );
};
