import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface WorkOrdersState {
  columns: {
    [key: string]: {
      [key: string]: boolean;
    }
  };
}

const initialState: WorkOrdersState = {
  columns: {},
};

export const workOrderSlice = createSlice({
  name: 'wo',
  initialState,
  reducers: {
    setActiveColumns: (state, action: PayloadAction<{ key: string, column: string, value: boolean }>) => {
      if (state.columns.hasOwnProperty(action.payload.key)) {
        state.columns[action.payload.key][action.payload.column] = action.payload.value;
      } else {
        state.columns[action.payload.key] = {
          [action.payload.column]: action.payload.value,
        };
      }
    },
  },
});

export const { setActiveColumns } = workOrderSlice.actions;

export const activeColumnsState = (key: string) => (state: RootState) => state.wo.columns[key];

export default workOrderSlice.reducer;
