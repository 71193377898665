import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { ApiPath } from '@constants/index';

interface AddMaterialParams {
  supplierId: string;
  materialId: string;
  price: number;
  code: string;
}

function addMaterial(params: AddMaterialParams) {
  return axios.post(`${apiBaseUrl}/${ApiPath.SupplierMaterials}`, params);
}

export function useCreateSupplierMaterial() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, AddMaterialParams>({
    mutationKey: [QueryKey.CreateSupplierMaterial],
    mutationFn: addMaterial,
  });
}
