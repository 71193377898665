import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { ApiPath } from '@constants/index';
import { CreateSupplierRequest, Supplier } from '@api/types';


function createSupplier(payload: CreateSupplierRequest) {
  return axios.post<Supplier>(`${apiBaseUrl}/${ApiPath.Suppliers}`, payload);
}

export function useCreateSupplier() {
  return useMutation<AxiosResponse<Supplier>, AxiosError<{ message: string }>, CreateSupplierRequest>({
    mutationKey: [QueryKey.CreateSupplier],
    mutationFn: createSupplier,
  });
}
