import { useAccessToken } from './useAccessToken';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppDispatch } from '@hooks/redux';
import { setIsLoggedIn } from '@store/auth';

export function useAxiosInterceptor() {
  const dispatch = useAppDispatch();

  const { token, setToken } = useAccessToken();
  const [isLoading, setIsLoading] = useState(true);

  const logout = () => {
    setToken(null);
    dispatch(setIsLoggedIn(false));
  };

  useEffect(() => {
    let reqInterceptor: number;
    let resInterceptor: number;

    if (token) {
      reqInterceptor = axios.interceptors.request.use(
        config => {
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        });
      resInterceptor = axios.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          if (error.response.status === 401) {
            logout();
          }

          return Promise.reject(error);
        });
    }

    return () => {
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, [token]);

  useEffect(() => {
    console.log('token', token);
    if (token) {
      dispatch(setIsLoggedIn(true));
    }

    if (!token) {
      logout();
    }

    setIsLoading(false);
  }, [token]);

  return {
    isLoading,
  };
}
