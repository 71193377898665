import { Button, Col, Form, notification, Row, Select } from 'antd';
import { WorkstationType } from '@constants/WorkstationType';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setWorkstationType, workstationTypeConfigState } from '@store/config';

const options = [
  { value: WorkstationType.Admin, label: 'Admin' },
  { value: WorkstationType.LaserCut, label: 'Taglio Laser' },
  { value: WorkstationType.Welding, label: 'Saldatura' },
  { value: WorkstationType.Assembly, label: 'Assemblaggio' },
  { value: WorkstationType.Administration, label: 'Amministrazione' },
];


export function WorkstationTab() {
  const dispatch = useAppDispatch();
  const workstationType = useAppSelector(workstationTypeConfigState);
  const [api, context] = notification.useNotification();

  const openNotification = () => {
    api.info({
      message: `Impostazioni salvate con successo!`,
      placement: 'topRight',
      type: 'success',
      duration: 1,
    });
  };

  const save = (values: { type: WorkstationType }) => {
    dispatch(setWorkstationType(values.type));
    openNotification();
  };

  return (
    <Row gutter={[16, 16]}>
      {context}
      <Col span={6}>
        <Form name="workstation-settings" layout="vertical" onFinish={save}>
          <Form.Item name="type" label="Tipo Postazione" initialValue={workstationType}>
            <Select options={options} style={{ width: '100%' }} />
          </Form.Item>

          <Button type="primary" htmlType="submit" form="workstation-settings">Salva</Button>
        </Form>
      </Col>
    </Row>
  );
}
