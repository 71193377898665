import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { ApiPath } from '@constants/index';

function createOrder(payload: { supplierId: string }) {
  return axios.post(`${apiBaseUrl}/${ApiPath.Orders}`, payload);
}

export function useCreateOrder() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, { supplierId: string }>({
    mutationKey: [QueryKey.CreateOrder],
    mutationFn: createOrder,
  });
}
