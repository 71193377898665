import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const fetchUserRoles = async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_PATH}/roles/${id}`);
    return response.data;
  } catch (error) {
    return {};
  }
};

export const useGetUserRolesQuery = (id: string, config?: { enabled: boolean }) => useQuery({
  queryKey: ['get-user-roles', id],
  queryFn: () => fetchUserRoles(id),
  enabled: id !== '' && (config?.enabled ?? true),
});
