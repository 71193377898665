import { Alert, Col, Form, InputNumber, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { useGetSupplierMaterials } from '@api/suppliers/useGetSupplierMaterials';
import { useCreateOrderMaterial } from '@api/orders/useCreateOrderMaterial';
import { useGetOrderMaterials } from '@api/orders/useGetOrderMaterials';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isAddMaterialModalOpenState, selectedOrderIdState, selectedSupplierIdState } from '@store/orders';

export function AddOrderMaterialModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isAddMaterialModalOpenState);
  const supplierId = useAppSelector(selectedSupplierIdState);
  const orderId = useAppSelector(selectedOrderIdState);

  const { data: supplierMaterials, isLoading: isLoadingSuppliers } = useGetSupplierMaterials(supplierId);
  const { data: orderMaterials, refetch: refetchOrderMaterials } = useGetOrderMaterials(orderId);
  const { mutate: addMaterial, isSuccess, reset, error } = useCreateOrderMaterial();

  useEffect(() => {
    if (isSuccess) {
      refetchOrderMaterials().then(() => {
        reset();
        form.resetFields();
        dispatch(closeModal('addMaterial'));
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    form.validateFields()
      .then(() => {
        addMaterial({ orderId, ...form.getFieldsValue() });
      })
      .catch((err => {
      }));
  };

  const onCancel = () => {
    reset();
    form.resetFields();
    dispatch(closeModal('addMaterial'));
  };

  if (isLoadingSuppliers) {
    return null;
  }

  return (
    <Modal title="Aggiungi Materiale" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="add-material" layout="vertical">
          <Form.Item
            name="materialId"
            label="Materiale"
            required
            rules={[{ required: true, message: 'Per favore seleziona un materiale' }]}
          >
            <Select
              options={supplierMaterials?.filter(sm => !orderMaterials?.some(om => om.materialId === sm.materialId)).map(s => ({
                label: s.description,
                value: s.materialId,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="quantity"
            label="Quantità"
            initialValue={1}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
