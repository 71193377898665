import axios from 'axios';
import { useMutation } from '@tanstack/react-query';


const updateUserRoles = async (payload: { id: string, roles: string[] }): Promise<null> => {
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_PATH}/roles/${payload.id}`, payload.roles);

    return null;
  } catch (error) {
    return null;
  }
};

export function useUpdateUserRoles() {
  return useMutation({
    mutationFn: updateUserRoles,
    mutationKey: ['set-user-roles'],
  });
}
