import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { UpdateQuotationProductParamRequest } from '@api/types';


function updateQuotationProductParam(product: UpdateQuotationProductParamRequest) {
  return axios.put(`${apiBaseUrl}/quotation-products`, product);
}

export const useUpdateQuotationProductParam = () => useMutation({
  mutationFn: updateQuotationProductParam,
  mutationKey: [QueryKey.UpdateQuotationProductParam],
});
