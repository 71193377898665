import { Alert, Col, Form, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { useGetSuppliers } from '@api/suppliers/useGetSuppliers';
import { useCreateOrder } from '@api/orders/useCreateOrder';
import { useGetOrders } from '@api/index';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isCreateOrderModalOpenState } from '@store/orders';

export function CreateOrderModal() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isCreateOrderModalOpenState);
  const [form] = Form.useForm();
  const { data: suppliers, isLoading: isLoadingSuppliers } = useGetSuppliers();

  const { refetch: refetchOrders } = useGetOrders();
  const { mutate: createOrder, isSuccess, error, reset } = useCreateOrder();

  useEffect(() => {
    if (isSuccess) {
      refetchOrders().then(() => {
        reset();
        form.resetFields();
        dispatch(closeModal('createOrder'));
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    form.validateFields().then(() => {
      createOrder(form.getFieldsValue());
    })
      .catch((err => {
      }));
  };

  const onCancel = () => {
    reset();
    form.resetFields();
    dispatch(closeModal('createOrder'));
  };

  if (isLoadingSuppliers) {
    return null;
  }

  return (
    <Modal title="Crea Ordine" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-order" layout="vertical">
          <Form.Item
            name="supplierId"
            label="Fornitore"
            required
            rules={[{ required: true, message: 'Per favore inserisci un fornitore' }]}
          >
            <Select options={suppliers?.map(s => ({ label: s.name, value: s.id }))} />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
