import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetQuotation } from '@api/quotations/useGetQuotation';
import { useGetCustomers } from '@api/customers';
import { useGetQuotationStatuses } from '@api/quotations/useGetQuotationStatuses';
import { useGetQuotationSources } from '@api/quotations/useGetQuotationSources';
import dayjs from 'dayjs';
import { getQuotationStatusLabel } from './utils';
import { useGetProducts } from '@api/products/useGetProducts';
import { useCreateQuotationProduct } from '@api/quotations/useCreateQuotationProduct';
import { QuotationProduct } from './QuotationProduct';
import { useForm } from 'antd/es/form/Form';
import { useUpdateQuotation } from '@api/quotations/useUpdateQuotation';
import { useDebouncedCallback } from 'use-debounce';
import { useGetQuotationFiles } from '@api/quotations/useGetQuotationFiles';
import { apiBaseUrl } from '@constants/constants';
import { useAccessToken } from '@hooks/useAccessToken';
import { useDeleteQuotationFile } from '@api/quotations/useDeleteQuotationFile';
import { useDownloadQuotationPdf } from '@api/quotations/useDownloadQuotationPdf';
import { FilePdfOutlined, MailOutlined } from '@ant-design/icons';
import { UpdateQuotationRequest } from '@api/types';

const ivaOptions = [
  { value: null, label: 'Custom' },
  { value: '0', label: '0%' },
  { value: '0.04', label: '4%' },
  { value: '0.11', label: '11%' },
  { value: '0.22', label: '22%' },
];

const baseDiscountRange = Array.from({ length: 11 }, (_, index) => index * 5);
const discountRange = Array.from({ length: 20 }, (_, index) => index * 5);
const depositRange = Array.from({ length: 21 }, (_, index) => index * 5);

const baseDiscountOptions = [
  { value: null, label: 'Custom' },
  ...baseDiscountRange.map(r => ({ value: String((r / 100).toFixed(2)), label: `${r}%` })),
];

const discountOptions = [
  { value: null, label: 'Custom' },
  ...discountRange.map(r => ({ value: String((r / 100).toFixed(2)), label: `${r}%` })),
];

const depositOptions = [
  { value: null, label: 'Custom' },
  ...depositRange.map(r => ({ value: String((r / 100).toFixed(2)), label: `${r}%` })),
];


export function EditQuotation() {
  const params = useParams();
  const [form] = useForm();

  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number>(1);

  const [baseDiscount, setBaseDiscount] = useState<string>('0');
  const [discount, setDiscount] = useState<string>('0');
  const [deposit, setDeposit] = useState<string>('0');
  const [iva, setIva] = useState<string>('0');

  const [isBaseDiscountDisabled, setIsBaseDiscountDisabled] = useState(true);
  const [isDiscountDisabled, setIsDiscountDisabled] = useState(true);
  const [isDepositDisabled, setIsDepositDisabled] = useState(true);
  const [isIvaDisabled, setIsIvaDisabled] = useState(true);

  const { token } = useAccessToken();

  const {
    data: quotation,
    isFetched: isFetchedQuotation,
    refetch: refetchQuotation,
  } = useGetQuotation(Number(params.id));

  const { data: products, isFetched: isFetchedProducts } = useGetProducts();
  const { data: statuses, isFetched: isFetchedStatuses } = useGetQuotationStatuses();
  const { data: customers, isFetched: isFetchedCustomers } = useGetCustomers();
  const { data: sources, isFetched: isFetchedSources } = useGetQuotationSources();
  const { data: files, isFetched: isFetchedFiles, refetch: refetchFiles } = useGetQuotationFiles(quotation?.id ?? 0);

  const { mutate: createQuotationProduct, isSuccess: isSuccessQuotationProduct } = useCreateQuotationProduct();
  const {
    mutate: updateQuotation,
    isSuccess: isSuccessUpdateQuotation,
    reset: resetUpdateQuotation,
  } = useUpdateQuotation();
  const { mutate: deleteQuotationFile, isSuccess: isSuccessDeleteQuotationFile } = useDeleteQuotationFile();
  const { mutate: downloadQuotationPdf } = useDownloadQuotationPdf();

  const debouncedUpdateQuotation = useDebouncedCallback((quotation: Partial<UpdateQuotationRequest>) => updateQuotation(quotation), 1000);

  const items = [
    { title: <Link to="/quotes">Preventivi</Link> },
    { title: `Preventivo ${quotation?.year}-${quotation?.serial}` },
  ];

  const customersOptions = [
    { key: '', value: 'Nessuno' },
    ...customers?.map(c => ({ key: c.id, value: `${c.name} ${c.firstName} ${c.lastName}` })) ?? [],
  ];

  const statusesOptions = statuses?.map(s => ({ key: s, value: getQuotationStatusLabel(s) })) ?? [];

  const sourcesOptions = [
    { key: '', value: 'Nessuno' },
    ...sources?.map(r => ({ key: r.id, value: r.description })) ?? [],
  ];

  const productItems = quotation?.products.map(p => ({
    key: p.id,
    label: p.description,
    children: <QuotationProduct product={p} refetch={refetchQuotation} />,
    extra:
      <Row>
        <Col>
          <Typography>Prezzo Unitario € {p.unitPrice}</Typography>
          <Typography>Prezzo Totale € {p.totalPrice}</Typography>
        </Col>
      </Row>,
  })) ?? [];

  const productOptions = products?.map(p => ({ key: p.id, value: p.description })) ?? [];

  const isFetched =
    isFetchedQuotation &&
    isFetchedStatuses &&
    isFetchedCustomers &&
    isFetchedSources &&
    isFetchedProducts &&
    isFetchedFiles;

  useEffect(() => {
    if (isLoading && isFetched) {
      setLoading(false);
    }
  }, [isFetched, isLoading]);

  useEffect(() => {
    if (quotation?.baseDiscount === null) {
      setIsBaseDiscountDisabled(false);
    } else {
      setIsBaseDiscountDisabled(true);
    }
  }, [quotation?.baseDiscount]);

  useEffect(() => {
    if (quotation?.discount === null) {
      setIsDiscountDisabled(false);
    } else {
      setIsDiscountDisabled(true);
    }
  }, [quotation?.discount]);

  useEffect(() => {
    if (quotation?.deposit === null) {
      setIsDepositDisabled(false);
    } else {
      setIsDepositDisabled(true);
    }
  }, [quotation?.deposit]);

  useEffect(() => {
    if (quotation?.ivaPercentage === null) {
      setIsIvaDisabled(false);
    } else {
      setIsIvaDisabled(true);
    }
  }, [quotation?.ivaPercentage]);

  useEffect(() => {
    if (isSuccessDeleteQuotationFile) {
      refetchFiles();
    }
  }, [isSuccessDeleteQuotationFile]);

  const onBaseDiscountSelect = (value: string | null) => {
    updateQuotation({
      id: Number(params.id),
      baseDiscount: value,
    });
  };

  useEffect(() => {
    setBaseDiscount(quotation?.baseDiscountAmount ?? '0');
    setDiscount(quotation?.discountAmount ?? '0');
    setDeposit(quotation?.depositAmount ?? '0');
    setIva(quotation?.ivaAmount ?? '0');
  }, [quotation]);

  const onBaseDiscountChange = (e: any) => {
    setBaseDiscount(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      baseDiscountAmount: e.target.value,
    });
  };

  const onDiscountSelect = (value: string | null) => {
    updateQuotation({
      id: Number(params.id),
      discount: value,
    });
  };

  const onDiscountChange = (e: any) => {
    setDiscount(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      discountAmount: e.target.value,
    });
  };

  const onDepositSelect = (value: string | null) => {
    updateQuotation({
      id: Number(params.id),
      deposit: value,
    });
  };

  const onDepositChange = (e: any) => {
    setDeposit(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      depositAmount: e.target.value,
    });
  };

  const onIvaSelect = (value: string | null) => {
    if (value === null) {
      setIsIvaDisabled(false);
    } else {
      setIsIvaDisabled(true);
    }

    updateQuotation({
      id: Number(params.id),
      ivaPercentage: value,
    });

    save({ ivaPercentage: value });
  };

  const onIvaChange = (e: any) => {
    setIva(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      ivaAmount: e.target.value,
    });
  };

  const save = (values: any) => {
    const payload: Partial<UpdateQuotationRequest> = { id: Number(params.id) };

    if (values.status !== '') {
      payload.status = values.status;
    }

    if (values.customerId !== '') {
      payload.customerId = values.customerId;
    } else {
      payload.customerId = null;
    }

    if (values.referrerId !== '') {
      payload.referrerId = values.referrerId;
    }

    if (values.sourceId !== '') {
      payload.sourceId = values.sourceId;
    }

    payload.reference = values.reference;
    payload.address = values.address;
    payload.city = values.city;
    payload.province = values.province;
    payload.postalCode = values.postalCode;
    payload.country = values.country;

    debouncedUpdateQuotation(payload);
  };

  const onAddProduct = () => {
    createQuotationProduct({
      quotationId: Number(params.id),
      productId: selectedProduct,
    });
  };

  const onSelect = () => {
    form.submit();
  };

  useEffect(() => {
    if (isSuccessQuotationProduct) {
      setIsModalOpen(false);
      refetchQuotation();
    }
  }, [isSuccessQuotationProduct]);

  useEffect(() => {
    if (isSuccessUpdateQuotation) {
      refetchQuotation().then((quotation) => {
        form.setFieldValue('referrer', quotation?.data?.customer?.referrer?.name ?? '');
        form.setFieldValue('address', quotation.data?.address ?? '');
        form.setFieldValue('city', quotation.data?.city ?? '');
        form.setFieldValue('province', quotation.data?.province ?? '');
        form.setFieldValue('postalCode', quotation.data?.postalCode ?? '');
        form.setFieldValue('country', quotation.data?.country ?? '');
      });
      resetUpdateQuotation();
    }
  }, [isSuccessUpdateQuotation]);

  const onCloseAddProduct = () => {
    setIsModalOpen(false);
  };

  const mailto = useMemo(
    () => {
      const nameItems = [];

      if (quotation?.customer?.name) {
        nameItems.push(quotation.customer.name);
      }

      if (quotation?.customer?.firstName) {
        nameItems.push(quotation.customer.firstName);
      }

      if (quotation?.customer?.lastName) {
        nameItems.push(quotation.customer.lastName);
      }

      const name = nameItems.join(' ');
      return `mailto:${quotation?.customer?.email ?? ''}?subject=Preventivo Abruzzo Lamiere&cc=amministrazione@abl.it&body=Buonasera,%0A%0AGentile ${name}, in allegato puo trovare il preventivo richiesto%0A%0ARestiamo in attesa di un vostro riscontro%0A%0A%0A%0ACordiali Saluti.`;
    },
    [quotation?.customer],
  );

  if (isLoading) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={
            <Flex gap="small" wrap>
              <Button onClick={() => downloadQuotationPdf(quotation)}><FilePdfOutlined /></Button>
              <Button onClick={() => window.location.href = mailto}><MailOutlined /></Button>
            </Flex>
          }
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form
                name="edit-quotatation"
                form={form}
                onFinish={save}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item
                      name="status"
                      label="Stato"
                      initialValue={quotation?.status ?? 'draft'}
                    >
                      <Select onSelect={onSelect}>
                        {statusesOptions.map((option) => (
                          <Select.Option key={option.key} value={option.key}>
                            {option.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="customerId"
                      label="Cliente"
                      initialValue={quotation?.customerId ?? ''}
                    >
                      <Select onSelect={onSelect}>
                        {customersOptions.map((option) => (
                          <Select.Option key={option.key} value={option.key}>
                            {option.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="referrer"
                      label="Segnalatore"
                      initialValue={quotation?.customer?.referrer && (quotation?.customer?.referrer?.type === 'business' ? quotation?.customer?.referrer?.name : `${quotation?.customer?.referrer?.firstName} ${quotation?.customer?.referrer?.lastName}`)}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      name="reference"
                      label="Riferimento"
                      initialValue={quotation?.reference ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="sourceId"
                      label="Provenienza"
                      initialValue={quotation?.sourceId ?? ''}
                    >
                      <Select onSelect={onSelect}>
                        {sourcesOptions.map((option) => (
                          <Select.Option key={option.key} value={option.key}>
                            {option.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="createdAt"
                      label="Data di Creazione"
                      initialValue={dayjs(quotation?.createdAt)}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      name="address"
                      label="Indirizzo"
                      initialValue={quotation?.address ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="city"
                      label="Città"
                      initialValue={quotation?.city ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="province"
                      label="Provincia"
                      initialValue={quotation?.province ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="postalCode"
                      label="CAP"
                      initialValue={quotation?.postalCode ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="country"
                      label="Nazione"
                      initialValue={quotation?.country ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Space direction="vertical">
                      <Typography>Prezzi</Typography>
                      <Input
                        className="readable-disabled-input"
                        addonBefore="Sconto Base"
                        addonAfter={
                          <Select
                            defaultValue={quotation?.baseDiscount}
                            options={baseDiscountOptions}
                            onSelect={onBaseDiscountSelect}
                          />
                        }
                        disabled={isBaseDiscountDisabled}
                        value={baseDiscount}
                        onChange={onBaseDiscountChange}
                      />

                      <Input
                        className="readable-disabled-input"
                        addonBefore="Sconto"
                        addonAfter={
                          <Select
                            defaultValue={quotation?.discount}
                            options={discountOptions}
                            onSelect={onDiscountSelect}
                          />
                        }
                        disabled={isDiscountDisabled}
                        value={discount}
                        onChange={onDiscountChange}
                      />

                      <Input
                        className="readable-disabled-input"
                        addonBefore="IVA"
                        addonAfter={
                          <Select
                            defaultValue={quotation?.ivaPercentage}
                            options={ivaOptions}
                            onSelect={onIvaSelect}
                          />
                        }
                        disabled={isIvaDisabled}
                        value={iva}
                        onChange={onIvaChange}
                      />

                      <Input
                        className="readable-disabled-input"
                        addonBefore="Acconto"
                        addonAfter={
                          <Select
                            defaultValue={quotation?.deposit}
                            options={depositOptions}
                            onSelect={onDepositSelect}
                          />
                        }
                        disabled={isDepositDisabled}
                        value={deposit}
                        onChange={onDepositChange}
                      />

                      <Divider />

                      <Input
                        className="readable-disabled-input"
                        addonBefore="Prezzo Totale"
                        addonAfter="€"
                        disabled
                        value={quotation?.rawTotal ?? 0}
                      />

                      <Input
                        className="readable-disabled-input"
                        addonBefore="Prezzo Scontato"
                        addonAfter="€"
                        disabled
                        value={quotation?.discountTotal ?? 0}
                      />

                      <Input
                        className="readable-disabled-input"
                        addonBefore="Prezzo Finale"
                        addonAfter="€"
                        disabled
                        value={quotation?.finalTotal ?? 0}
                      />
                    </Space>
                  </Col>

                  <Col span={6}>
                    <Row align="middle" justify="space-between" style={{ marginBottom: 16 }}>
                      <Typography>Files</Typography>

                      <Upload
                        name="file"
                        action={`${apiBaseUrl}/quotations/${quotation?.id}/files`}
                        headers={{
                          Authorization: `Bearer ${token}`,
                        }}
                        onChange={(e) => {
                          if (e.file.status === 'done') {
                            refetchFiles();
                          }
                        }}
                        showUploadList={false}
                      >
                        <Button type="dashed" block>Carica File</Button>
                      </Upload>
                    </Row>

                    <List
                      dataSource={files?.map(f => ({ item: f }))}
                      renderItem={({ item }) => (
                        <List.Item>
                          <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                            <Col>
                              <a
                                href={`${apiBaseUrl}/files/${item.fileId}${item.extension ? `.${item.extension}` : ''}`}
                                target="_blank"
                                rel="noreferrer"
                              >{item.originalName}</a>
                            </Col>

                            <Space>
                              <Button
                                onClick={() => deleteQuotationFile({
                                  quotationId: quotation?.id ?? 0,
                                  quotationFileId: item.id,
                                })}
                              >Elimina</Button>
                            </Space>
                          </Row>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>

      <Divider />

      {/*{productItems.length > 0 && (*/}
      {/*  <Row gutter={16}>*/}
      {/*    <Col span={12}>*/}
      {/*      <Card title="Opzioni">*/}

      {/*      </Card>*/}
      {/*    </Col>*/}

      {/*    <Col span={12}>*/}
      {/*      <Card title="Materiali">*/}

      {/*      </Card>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}

      {productItems.length > 0 && (
        <Col span={24} style={{ marginBottom: '100px' }}>
          <Collapse
            size="large"
            items={productItems}
            defaultActiveKey={[productItems[0].key]}
          />
        </Col>
      )}

      <Modal title="Aggiungi Prodotto" open={isModalOpen} onOk={onAddProduct} onCancel={onCloseAddProduct}>
        <Form id="new-product" layout="vertical">
          <Form.Item
            name="product"
            label="Seleziona il tipo di prodotto che vuoi aggiungere al preventivo"
            initialValue={productOptions[0].key}
          >
            <Select onSelect={v => setSelectedProduct(v)} style={{ width: '100%' }}>
              {productOptions.map((option) => (
                <Select.Option key={option.key} value={option.key}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}
