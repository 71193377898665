import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { QueryKey } from '@constants/queries';
import { ApiPath } from '@constants/index';
import { Product } from '@api/types';

async function getProduct(id: string): Promise<Product | undefined> {
  try {
    const response = await axios.get(`${apiBaseUrl}/${ApiPath.Products}/${id}`);

    return response.data;
  } catch (e) {
    return undefined;
  }
}

export const useGetProduct = (id: string) => useQuery({
  queryKey: [QueryKey.GetProduct, id],
  queryFn: () => getProduct(id),
  enabled: id !== '',
});
