import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { CommissionMaterial } from '@api/types/commissions';

async function getCommissionMaterials(id: string): Promise<CommissionMaterial[]> {
  const response = await axios.get<CommissionMaterial[]>(`${apiBaseUrl}/${ApiPath.Commissions}/${id}/materials`);
  return response.data;
}

export function useGetCommissionMaterials(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetCommissionMaterials, id],
    queryFn: () => getCommissionMaterials(id),
    enabled: id !== '',
  });
}
