import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { OrderMaterial } from '@api/types';

async function getOrderMaterials(id: string): Promise<OrderMaterial[]> {
  const response = await axios.get<OrderMaterial[]>(`${apiBaseUrl}/${ApiPath.Orders}/${id}/materials`);
  return response.data;
}

export function useGetOrderMaterials(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetOrderMaterials, id],
    queryFn: () => getOrderMaterials(id),
    enabled: id !== '',
  });
}
