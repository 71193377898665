import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

function deleteQuotationFile({ quotationId, quotationFileId }: { quotationId: number, quotationFileId: string }) {
  return axios.delete(`${apiBaseUrl}/${ApiPath.Quotations}/${quotationId}/files/${quotationFileId}`);
}

export function useDeleteQuotationFile() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, { quotationId: number, quotationFileId: string }>({
    mutationKey: [QueryKey.DeleteQuotationFile],
    mutationFn: deleteQuotationFile,
  });
}
