import { Avatar, Card, Col, Row, Table, theme } from 'antd';
import { useGetWorkers } from '@api/workers';
import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { Worker } from '@api/types';

export function WorkersRoute() {
  const { data: workers } = useGetWorkers();
  const { token } = theme.useToken();

  console.log('workers', workers);

  const columns: ColumnProps<Worker>[] = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Presente',
      key: 'present',
      render: (_, record) => (
        <Avatar
          size={14}
          style={{ backgroundColor: record.isPresent ? token.green : token.colorFill }}
        />
      ),
    },

    {
      title: 'In Pausa',
      key: 'break',
      render: (_, record) => (
        <Avatar
          size={14}
          style={{ backgroundColor: record.isOnBreak ? token.red : token.colorFill }}
        />
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Lavoratori">
          <Table
            columns={columns}
            dataSource={workers}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: workers?.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
    </Row>
  );
}
