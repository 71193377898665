import { useEffect } from 'react';
import { useAppDispatch } from '@hooks/redux';
import { setAccessToken, setIsLoggedIn } from '@store/auth';


export function Logout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAccessToken(null));
    dispatch(setIsLoggedIn(false));
  }, []);

  return null;
}
