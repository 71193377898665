import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

function deleteSupplier(id: string) {
  return axios.delete(`${apiBaseUrl}/${ApiPath.Suppliers}/${id}`);
}

export function useDeleteSupplier() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, string>({
    mutationKey: [QueryKey.DeleteSupplier],
    mutationFn: deleteSupplier,
  });
}
