import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { SupplierMaterial } from '@api/types';

async function getSupplierMaterial(id: string): Promise<SupplierMaterial> {
  const response = await axios.get<SupplierMaterial>(`${apiBaseUrl}/${ApiPath.SupplierMaterials}/${id}`);
  return response.data;
}

export function useGetSupplierMaterial(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetSupplierMaterial, id],
    queryFn: () => getSupplierMaterial(id),
    enabled: id !== '',
  });
}
