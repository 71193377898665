import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';

function createQuotation() {
  return axios.post(`${apiBaseUrl}/quotations`);
}

export const useCreateQuotation = () => useMutation({
  mutationFn: createQuotation,
  mutationKey: [QueryKey.CreateQuotation],
});
