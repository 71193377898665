import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';

function createCustomer(customer: any) {
  return axios.post(`${apiBaseUrl}/customers`, customer);
}

export const useCreateCustomer = () => useMutation({
  mutationFn: createCustomer,
  mutationKey: [QueryKey.CreateCustomer],
});
