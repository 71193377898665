import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  TableProps,
  Typography,
} from 'antd';
import { useGetListOptionTypes } from '@api/list-options/useGetListOptionTypes';
import React, { useEffect, useState } from 'react';
import { useGetListOptions } from '@api/list-options/useGetListOptions';
import { useUpdateListOption } from '@api/list-options/useUpdateListOption';
import { SelectListOptions } from '@api/types';


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: SelectListOptions;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export function PriceListRoute() {
  const [selected, setSelected] = useState<string>('');
  const [form] = Form.useForm();

  const { data: listOptionTypes } = useGetListOptionTypes();
  const {
    data: listOptions,
    isLoading: isLoadingListOptions,
    refetch: refetcListOptions,
  } = useGetListOptions(selected);

  const { mutate: updateListOption, isSuccess: isSuccessUpdateListOption } = useUpdateListOption();

  const [editingKey, setEditingKey] = useState(0);
  const isEditing = (record: SelectListOptions) => record.id === editingKey;

  const edit = (record: SelectListOptions) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const save = async (id: number) => {
    updateListOption({ id, option: form.getFieldsValue() });
  };

  useEffect(() => {
    if (isSuccessUpdateListOption) {
      refetcListOptions();
      setEditingKey(0);
    }
  }, [isSuccessUpdateListOption]);

  const columns = [
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
      editable: true,
    },
    {
      title: 'Prezzo',
      dataIndex: 'price',
      key: 'price',
      editable: true,
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_: any, record: SelectListOptions) => {
        const editable = isEditing(record);

        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.id)} style={{ marginInlineEnd: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button type="link">Cancel</Button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== 0} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
      align: 'right',
    },
  ];

  useEffect(() => {
    if (listOptionTypes) {
      setSelected(listOptionTypes[0].key);
    }
  }, [listOptionTypes]);

  // @ts-ignore
  const mergedColumns: TableProps<SelectListOptions>['columns'] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: SelectListOptions) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });


  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          title="Listini Prezzi"
          className="responsive-card"
        >

          <Row justify="space-between" style={{ marginTop: 40, alignItems: 'flex-end' }}>
            <Col span={6}>
              <Typography.Text>Listino</Typography.Text>
              <Select
                options={listOptionTypes?.map(v => ({ value: v.key, label: v.key }))}
                style={{ width: '100%' }}
                value={selected}
                onChange={v => setSelected(v)}
              />
            </Col>
            <Flex>
              <Button type="primary" ghost>Aggiungi</Button>
            </Flex>
          </Row>

          <Col span={24} style={{ marginTop: 40 }}>
            <Spin spinning={isLoadingListOptions}>
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  columns={mergedColumns}
                  dataSource={listOptions}
                  rowKey="id"
                  size="small"
                />
              </Form>
            </Spin>
          </Col>
        </Card>
      </Col>
    </Row>
  );
}
