import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { useNotification } from '@hooks/useNotification';

const SOCKET_SERVER_URL = 'ws://localhost:9999';

function useSocket(token: string | null) {
  const socketRef = useRef<Socket | null>(null);
  const { openInfoMessage, contextHolder } = useNotification();

  useEffect(() => {
    socketRef.current = io(SOCKET_SERVER_URL, {
      auth: {
        token: token,
      },
    });

    socketRef.current.on('connect', () => {
      console.log('Connected to the server');
    });

    socketRef.current.on('events', (message: string) => {
      console.log('message', message);

      openInfoMessage(message);
    });

    return () => {
      socketRef.current?.disconnect();
    };
  }, [token]);

  const sendMessage = (message: string) => {
    if (socketRef.current && message) {
      socketRef.current.emit('events', message);
    }
  };

  return { sendMessage, contextHolder };
}

export default useSocket;
