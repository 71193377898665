import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Order } from '@api/types';


export function useConfirmOrder() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, string>({
    mutationKey: [QueryKey.UpdateOrder],
    mutationFn: (orderId: string) => {
      return axios.put<Order>(`${apiBaseUrl}/${ApiPath.Orders}/${orderId}/confirm`);
    },
  });
}
