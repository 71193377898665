import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { ApiPath } from '@constants/index';

interface UpdateSupplierMaterialParams {
  id: string;
  payload: {
    price: number;
    code: string;
  };
}

function updateSupplierMaterial(params: UpdateSupplierMaterialParams) {
  return axios.put(`${apiBaseUrl}/${ApiPath.SupplierMaterials}/${params.id}`, params.payload);
}

export function useUpdateSupplierMaterial() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, UpdateSupplierMaterialParams>({
    mutationKey: [QueryKey.UpdateSupplierMaterial],
    mutationFn: updateSupplierMaterial,
  });
}
