export function getCustomerPaymentModeLabel(mode: string) {
  switch (mode) {
    case 'on-withdraw':
      return 'Al Ritiro';
    case 'bank-transfer-on-ready':
      return 'Bonifico a Merce Pronta';
    case 'check-30-days':
      return 'Assegno 30 Giorni';
    case 'riba':
      return 'R.I.B.A.';
    case 'mark':
      return 'Contrassegno +3%';
    case 'tbd':
      return 'Da Definire';
    default:
      return '';
  }
}
