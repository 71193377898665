import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { SelectListOptionTypes } from '@api/types';

export const fetchListOptionTypes = async (): Promise<SelectListOptionTypes[]> => {
  try {
    const response = await axios.get<SelectListOptionTypes[]>(`${apiBaseUrl}/list-options`);

    return response.data || [];
  } catch (error) {
    console.error('[get-customers]', error);
    return [];
  }
};

export function useGetListOptionTypes() {
  return useQuery({
    queryKey: [QueryKey.GetListOptionTypes],
    queryFn: fetchListOptionTypes,
  });
}
