import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/es/storage';
import { persistReducer, persistStore } from 'redux-persist';

// reducers
import authReducer from './auth';
import commissionsReducer from './commissions';
import configReducer from './config';
import customersReducer from './customers';
import ordersReducer from './orders';
import sourcesReducer from './sources';
import suppliersReducer from './suppliers';
import userReducer from './user';
import warehouseReducer from './warehouse';
import workOrdersReducer from './work-orders';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['accessToken'],
};

const configPersistConfig = {
  key: 'config',
  storage: storage,
  whitelist: ['workstationType'],
};

const workOrdersPersistConfig = {
  key: 'wo',
  storage: storage,
  whitelist: ['columns'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  commissions: commissionsReducer,
  config: persistReducer(configPersistConfig, configReducer),
  customers: customersReducer,
  orders: ordersReducer,
  sources: sourcesReducer,
  suppliers: suppliersReducer,
  user: userReducer,
  warehouse: warehouseReducer,
  wo: persistReducer(workOrdersPersistConfig, workOrdersReducer),
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
