import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Worker } from '@api/types';

export function useGetWorkers() {
  return useQuery({
    queryKey: [QueryKey.GetWorkers],
    queryFn: async () => {
      try {
        const response = await axios.get<Worker[]>(`${apiBaseUrl}/${ApiPath.Workers}`);

        return response.data;
      } catch (err) {
        console.error(err);
      }
    },
  });
}
