import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { WorkPhase } from '@api/types';

async function getWorkPhases(): Promise<WorkPhase[]> {
  try {
    const results = await axios.get<WorkPhase[]>(`${apiBaseUrl}/${ApiPath.WorkPhases}`);

    return results.data;
  } catch (err) {
    console.error('[getWorkPhases]', err);
    return [];
  }
}

export function useGetWorkPhases() {
  return useQuery({
    queryKey: [QueryKey.GetWorkPhases],
    queryFn: getWorkPhases,
  });
}
