import { Breadcrumb, Button, Card, Checkbox, Col, Modal, Row, Select, Space, Table, Typography } from 'antd';
import {
  useAddCommission,
  useGetWorkOrderById,
  useStartWorkOrder,
  useWocActivateAllWorkPhases,
  useWocUpdatePhase,
} from '@api/work-orders';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useGetCommissions } from '@api/commissions';
import { ColumnProps } from 'antd/es/table';
import { WorkOrderCommission } from '@api/types';
import { useGetWorkPhases } from '@api/work-phases';
import { useNotification } from '@hooks/useNotification';
import { getWorkOrderStatusLabel } from '@routes/work-orders/utils';


export function EditWorkOrder() {
  const params = useParams();
  const { contextHolder, openErrorMessage } = useNotification();

  const [addCommissionId, setAddCommissionId] = useState<string | null>(null);
  const [isAddCommissionModalOpen, setIsAddCommissionModalOpen] = useState(false);

  const { data: commissions, isLoading: isLoadingCommissions } = useGetCommissions();
  const { data: workPhases } = useGetWorkPhases();

  const {
    mutate: wocActivateAllWorkPhases,
    isSuccess: isSuccessWocActivateAllWorkPhases,
    reset: resetWocActivateAllWorkPhases,
  } = useWocActivateAllWorkPhases();

  const {
    mutate: wocUpdatePhase,
    isSuccess: isSuccessWocUpdatePhase,
    reset: resetWocUpdatePhase,
  } = useWocUpdatePhase();

  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    refetch: refetchWorkOrder,
  } = useGetWorkOrderById(params?.id ?? '');

  const { mutate: addCommission, isSuccess: isSuccessAddCommission } = useAddCommission();

  const {
    mutate: startWorkOrder,
    isSuccess: isSuccessStartWorkOrder,
    reset: resetStartWorkOrder,
    isError: isErrorStartWorkOrder,
    error: errorWorkOrder,
  } = useStartWorkOrder();

  const items = [
    { title: <Link to="/work-orders">Ordini di Lavoro</Link> },
    { title: `${workOrder?.year}-${workOrder?.serial}` },
  ];

  const onStart = () => {
    startWorkOrder(params?.id ?? '');
  };

  const onOkAddCommission = () => {
    if (params.id && addCommissionId) {
      addCommission({ workOrderId: params?.id ?? '', commissionId: addCommissionId });

      setAddCommissionId(null);
    }
  };

  const onCancelAddQuotation = () => {
    setIsAddCommissionModalOpen(false);
    setAddCommissionId(null);
  };

  const onDeleteCommission = (commission: WorkOrderCommission) => {
  };

  const activateAll = (commission: WorkOrderCommission) => {
    wocActivateAllWorkPhases({ woId: workOrder?.id ?? '', wocId: commission.id });
  };

  useEffect(() => {
    if (isSuccessAddCommission) {
      refetchWorkOrder();
      setIsAddCommissionModalOpen(false);
    }
  }, [isSuccessAddCommission]);

  useEffect(() => {
    if (isSuccessWocActivateAllWorkPhases) {
      resetWocActivateAllWorkPhases();
      refetchWorkOrder();
    }
  }, [isSuccessWocActivateAllWorkPhases]);

  useEffect(() => {
    if (isSuccessWocUpdatePhase) {
      resetWocUpdatePhase();
      refetchWorkOrder();
    }
  }, [isSuccessWocUpdatePhase]);

  useEffect(() => {
    if (isSuccessStartWorkOrder) {
      resetStartWorkOrder();
      refetchWorkOrder();
    }
  }, [isSuccessStartWorkOrder]);

  useEffect(() => {
    console.log(errorWorkOrder);
    if (isErrorStartWorkOrder && errorWorkOrder?.response?.data?.message) {
      switch (errorWorkOrder.response.data.message) {
        case 'no_commission':
          openErrorMessage(`Impossibile avviare, l'ordine di lavoro deve contenere almeno una commessa`);
          break;
        case 'empty_phases':
          openErrorMessage('Impossibile avviare, una o più commissioni non hanno fasi associate');
          break;
      }
    }
  }, [isErrorStartWorkOrder]);

  const columns: ColumnProps<WorkOrderCommission>[] = [
    {
      title: 'Cliente',
      key: 'customer',
      render: (_, record) => <Typography>{record?.quotation?.customer?.name ?? ''}</Typography>,
    },
    {
      title: 'Riferimento',
      key: 'reference',
      render: (_, record) => <Typography>{record?.quotation?.reference ?? ''}</Typography>,
    },
    {
      title: 'Commessa',
      key: 'commission',
      render: (_, record) => <Link to={`/commissions/${record.id}`}>{record.year}-{record.serial}</Link>,
      width: 200,
    },
    {
      title: 'Preventivo',
      key: 'quotation',
      width: 200,
      render: (_, record) =>
        <Link to={`/quotes/${record?.quotation?.id}`}>{record?.quotation?.year}-{record?.quotation?.serial}</Link>,
    },
    {
      title: 'Taglio Laser',
      width: 100,
      align: 'center',
      render: (_, record) => <Checkbox
        disabled={workOrder?.started}
        checked={record.phases.some(p => p.key === 'laser-cut')}
        onClick={() => wocUpdatePhase({
          action: record.phases.some(p => p.key === 'laser-cut') ? 'remove' : 'add',
          wocId: record.id,
          wpId: workPhases?.find(wp => wp.key === 'laser-cut')?.id ?? '',
        })}
      />,
    },
    {
      title: 'Piegatura',
      width: 100,
      align: 'center',
      render: (_, record) => <Checkbox
        disabled={workOrder?.started}
        checked={record.phases.some(p => p.key === 'folding')}
        onClick={() => wocUpdatePhase({
          action: record.phases.some(p => p.key === 'folding') ? 'remove' : 'add',
          wocId: record.id,
          wpId: workPhases?.find(wp => wp.key === 'folding')?.id ?? '',
        })}
      />,
    },
    {
      title: 'Saldatura',
      width: 100,
      align: 'center',
      render: (_, record) => <Checkbox
        disabled={workOrder?.started}
        checked={record.phases.some(p => p.key === 'welding')}
        onClick={() => wocUpdatePhase({
          action: record.phases.some(p => p.key === 'welding') ? 'remove' : 'add',
          wocId: record.id,
          wpId: workPhases?.find(wp => wp.key === 'welding')?.id ?? '',
        })}
      />,
    },
    {
      title: 'Verniciatura',
      width: 100,
      align: 'center',
      render: (_, record) => <Checkbox
        disabled={workOrder?.started}
        checked={record.phases.some(p => p.key === 'painting')}
        onClick={() => wocUpdatePhase({
          action: record.phases.some(p => p.key === 'painting') ? 'remove' : 'add',
          wocId: record.id,
          wpId: workPhases?.find(wp => wp.key === 'painting')?.id ?? '',
        })}
      />,
    },
    {
      title: 'Assemblaggio',
      width: 100,
      align: 'center',
      render: (_, record) => <Checkbox
        disabled={workOrder?.started}
        checked={record.phases.some(p => p.key === 'assembly')}
        onClick={() => wocUpdatePhase({
          action: record.phases.some(p => p.key === 'assembly') ? 'remove' : 'add',
          wocId: record.id,
          wpId: workPhases?.find(wp => wp.key === 'assembly')?.id ?? '',
        })}
      />,
    },
    {
      title: 'Fase Corrente',
      key: 'current_phase',
      render: (_, record) => <Typography>{getWorkOrderStatusLabel(record.currentPhase?.key)}</Typography>,
      align: 'right',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onDeleteCommission(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  if (workOrder && !workOrder.started) {
    columns.splice(9, 0, {
      title: '',
      width: 100,
      key: 'activate_all',
      render: (_, record) => <Button type="link" onClick={() => activateAll(record)}>Attiva Tutto</Button>,
    });
  }

  if (isLoadingWorkOrder || isLoadingCommissions) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={
            <Space size="middle">
              {workOrder && !workOrder.started &&
                <Button type="primary" ghost onClick={() => setIsAddCommissionModalOpen(true)}>Aggiungi
                  Commessa</Button>}
              {workOrder && !workOrder.started && workOrder.commissions.length > 0 &&
                <Button type="primary" onClick={onStart}>Avvia</Button>}
            </Space>
          }
        >
          <Table
            columns={columns}
            dataSource={workOrder?.commissions}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: workOrder?.commissions.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />

          {/*<Typography.Title>Preventivi</Typography.Title>*/}
          {/*{workOrder?.quotations?.map(q => (*/}
          {/*  <Card title={`Preventivo: ${q.year}-${q.serial}`} style={{ marginBottom: 20 }}>*/}
          {/*    <Row>*/}
          {/*      <Col span={12}>*/}
          {/*        <Typography.Title level={5}>Anagrafica</Typography.Title>*/}
          {/*        <Typography>Cliente: {`${q.customer?.firstName ?? ''} ${q.customer?.lastName ?? ''} ${q.customer?.name ?? ''}`}</Typography>*/}
          {/*        <Typography>Telefono: {q.customer?.phoneNumber} </Typography>*/}
          {/*        <Typography>Email: {q.customer?.email}</Typography>*/}
          {/*        <Typography>Indirizzo: {q?.address} {q?.city} {q?.province} {q?.postalCode} {q?.country}</Typography>*/}
          {/*        <Typography>Riferimento: {q?.reference}</Typography>*/}
          {/*        <Typography>Segnalatore: {q.referrer?.description}</Typography>*/}
          {/*      </Col>*/}

          {/*      <Col>*/}
          {/*        <Typography.Title level={5}>Prodotti</Typography.Title>*/}

          {/*        <List*/}
          {/*          dataSource={q.products}*/}
          {/*          renderItem={item => (*/}
          {/*            <List.Item style={{ borderBottomWidth: 0 }}>*/}
          {/*              <Typography.Title level={5} style={{ margin: 0 }}>*/}
          {/*                <Link to={`/work-orders/${params.id}/quotations/${q.id}/product/${item.id}`}>*/}
          {/*                  {item.quantity} x {item.description}*/}
          {/*                </Link>*/}
          {/*              </Typography.Title>*/}
          {/*            </List.Item>*/}
          {/*          )}*/}
          {/*        />*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </Card>*/}
          {/*))}*/}

          {/*{workOrder?.products?.map(p => (*/}
          {/*  <Card title={`Prodotto: ${p.description}`}>*/}

          {/*  </Card>*/}
          {/*))}*/}
        </Card>
      </Col>

      <Modal
        title="Aggiungi Preventivo"
        open={isAddCommissionModalOpen}
        onOk={onOkAddCommission}
        onCancel={onCancelAddQuotation}
      >
        <Col span={24} style={{ marginTop: 20, marginBottom: 40 }}>
          <Typography>Seleziona la commessa da aggiungere all'ordine di lavoro</Typography>
          <Select
            options={commissions?.map(c => ({ value: c.id, label: `${c.year}-${c.serial}` }))}
            value={addCommissionId}
            onSelect={id => setAddCommissionId(id)}
            style={{ width: '100%' }}
          />
        </Col>
      </Modal>
      {contextHolder}
    </Row>
  );
}
