import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const fetchRoles = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_PATH}/roles`);
    return response.data;
  } catch (error) {
    return {};
  }
};

export const useGetRolesQuery = () => useQuery({
  queryKey: ['get-roles'],
  queryFn: fetchRoles,
});
