import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { QueryKey } from '@constants/queries';
import { Order } from '@api/types';

async function downloadOrderPdf(order: Order | null | undefined): Promise<void> {
  const response = await axios.get(`${apiBaseUrl}/${ApiPath.Orders}/${order?.id}/pdf`, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `ordine-${order?.year}-${order?.serial}.pdf`);
  document.body.appendChild(link);
  link.click();
}

export function useDownloadOrderPdf() {
  return useMutation({
    mutationKey: [QueryKey.DownloadOrderPdf],
    mutationFn: downloadOrderPdf,
  });
}
