import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Spin,
  Table,
  TableColumnProps,
  Typography,
  Upload,
} from 'antd';
import { apiBaseUrl } from '@constants/constants';
import React, { useEffect, useState } from 'react';
import { useGetCommission } from '@api/commissions/useGetCommission';
import { Link, useParams } from 'react-router-dom';
import { useAccessToken } from '@hooks/useAccessToken';
import { useGetCommissionFiles } from '@api/commissions/useGetCommissionFiles';
import { EntityFile } from '@api/types';
import { useGetCommissionMaterials } from '@api/commissions/useGetCommissionMaterials';
import { ColumnProps } from 'antd/es/table';
import { CommissionMaterial } from '@api/types/commissions';
import { useUpdateCommission } from '@api/commissions/useUpdateCommission';

export function CommissionRoute() {
  const params = useParams();
  const { token } = useAccessToken();
  const [note, setNote] = useState<string>('');

  const { data: commission, refetch: refetchCommission } = useGetCommission(params?.id ?? '');
  const { data: materials } = useGetCommissionMaterials(params?.id ?? '');
  const { data: files, isLoading: isLoadingFiles, refetch: refetchFiles } = useGetCommissionFiles(params?.id ?? '');
  const {
    mutate: updateCommission,
    isSuccess: isSuccessUpdateCommission,
    reset: resetUpdateCommission,
  } = useUpdateCommission();

  const onSave = () => {
    if (commission?.id) {
      updateCommission({
        id: commission.id,
        note,
      });
    }
  };

  const onDeleteFile = (file: EntityFile) => {
  };

  useEffect(() => {
    setNote(commission?.note ?? '');
  }, [commission]);

  useEffect(() => {
    if (isSuccessUpdateCommission) {
      resetUpdateCommission();
      refetchCommission();
      // .then(() => {
      //   setNote(commission?.note ?? '');
      // });
    }
  }, [isSuccessUpdateCommission]);

  const materialsColumns: ColumnProps<CommissionMaterial>[] = [
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Fornitore',
      key: 'supplier',
      render: (_, record) => <Typography>{record.supplier.name}</Typography>,
    },
    {
      title: 'Ordine',
      key: 'order',
      render: (_, record) => <Typography></Typography>,
    },
    {
      title: 'Quantità',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Disponibile',
      dataIndex: 'available',
      key: 'available',
    },
  ];

  const fileColumns: TableColumnProps<EntityFile>[] = [
    {
      title: 'Nome',
      key: 'originalName',
      render: (_, record) => <a
        href={`${apiBaseUrl}/files/${record.fileId}${record.extension ? `.${record.extension}` : ''}`}
        target="_blank"
        rel="noreferrer"
      >{record.originalName}</a>,
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onDeleteFile(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  const items = [
    { title: <Link to={`/commissions`} style={{ marginInline: 'unset' }}>Commesse</Link> },
    { title: `${commission?.year}-${commission?.serial}` },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title={<Breadcrumb items={items} />} extra={<Button type="primary" onClick={onSave}>Salva</Button>}>
          <Typography.Title level={5}>Note</Typography.Title>
          <Input.TextArea rows={6} onChange={e => setNote(e.target.value)} value={note} />
        </Card>

        <Divider />

        <Card
          title="Materiali" extra={<Button
          type="primary" onClick={() => {
        }}
        >Genera Ordini</Button>}
        >
          <Table
            columns={materialsColumns}
            dataSource={materials}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: files?.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>

        <Divider />

        <Card
          title="Files"
          extra={
            <Upload
              name="file"
              action={`${apiBaseUrl}/commissions/${commission?.id}/files`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              onChange={(e) => {
                if (e.file.status === 'done') {
                  refetchFiles();
                }
              }}
              showUploadList={false}
            >
              <Button type="dashed" block>Carica File</Button>
            </Upload>
          }
        >
          <Spin spinning={isLoadingFiles}>
            <Table
              columns={fileColumns}
              dataSource={files}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: files?.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}
