import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';


const { Content } = Layout;

export function BaseLayout() {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <Outlet />
      </Content>
    </Layout>
  );
}
