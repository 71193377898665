import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';


async function getAllQuotationStatuses() {
  try {
    const response = await axios.get<string[]>(`${apiBaseUrl}/quotation-statuses`);

    return response.data || [];
  } catch (e) {
    return [];
  }
}

export function useGetQuotationStatuses() {
  return useQuery({
    queryKey: [QueryKey.GetQuotationStatuses],
    queryFn: getAllQuotationStatuses,
  });
}
