import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Source } from '@api/types';

async function getAllQuotationSources(): Promise<Source[]> {
  try {
    const response = await axios.get<Source[]>(`${apiBaseUrl}/quotation-sources`);

    return response.data || [];
  } catch (e) {
    return [];
  }
}

export function useGetQuotationSources() {
  return useQuery({
    queryKey: [QueryKey.GetQuotationSources],
    queryFn: getAllQuotationSources,
  });
}
