import { Col, Input, InputNumber, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useUpdateQuotationProductParam } from '@api/quotations/useUpdateQuotationProductParam';
import { useUpdateQuotationProduct } from '@api/quotations';
import { useDebouncedCallback } from 'use-debounce';
import { Product, ProductOption, ProductOptionParam } from '@api/types';


export interface QuotationProductProps {
  product: Product;
  refetch: () => void;
}

export function QuotationProduct({ product, refetch }: QuotationProductProps) {
  const [formProduct, setFormProduct] = useState<Product>();

  useEffect(() => {
    setFormProduct(product);
  }, [product]);

  const {
    mutate: updateQuotationProductParam,
    isSuccess: isSuccessUpdateQuotationProductParam,
  } = useUpdateQuotationProductParam();

  const { mutate: updateQuotationProduct, isSuccess: isSuccessUpdateQuotationProduct } = useUpdateQuotationProduct();

  // price
  const debouncedUpdatePrice = useDebouncedCallback(
    (option: ProductOption, param: ProductOptionParam, selected: string) => {
      if (formProduct && param.values) {
        updateQuotationProductParam({
          productId: formProduct.id,
          optionId: option.id,
          paramId: param.id,
          value: Number(selected),
          price: param.values.find(v => v.id === Number(selected))?.price ?? '',
        });
      }
    },
    1000,
  );

  const updatePrice = (option: ProductOption, param: ProductOptionParam, selected: string, optionIndex: number, paramIndex: number) => {
    if (formProduct && selected !== null) {
      const payload = { ...formProduct };

      payload.options[optionIndex].params[paramIndex].value = selected;

      setFormProduct(payload);
      debouncedUpdatePrice(option, param, selected);
    }
  };

  // number
  const debouncedOnChangeNumber = useDebouncedCallback(
    (option: ProductOption, param: ProductOptionParam, value: number | null) => {
      if (formProduct) {
        updateQuotationProductParam({
          productId: formProduct.id,
          optionId: option.id,
          paramId: param.id,
          value: Number(value),
        });
      }
    },
    1000,
  );

  const onChangeNumber = (option: ProductOption, param: ProductOptionParam, value: number | null, optionIndex: number, paramIndex: number) => {
    if (formProduct && value !== null) {
      const payload = { ...formProduct };

      payload.options[optionIndex].params[paramIndex].value = value;

      setFormProduct(payload);
      debouncedOnChangeNumber(option, param, value);
    }
  };

  // text
  const debouncedOnChangeText = useDebouncedCallback(
    (option: ProductOption, param: ProductOptionParam, value: string) => {
      if (formProduct) {
        updateQuotationProductParam({
          productId: formProduct.id,
          optionId: option.id,
          paramId: param.id,
          value: value,
        });
      }
    },
    1000,
  );

  const onChangeText = (option: ProductOption, param: ProductOptionParam, value: string, optionIndex: number, paramIndex: number) => {
    if (formProduct) {
      const payload = { ...formProduct };

      payload.options[optionIndex].params[paramIndex].value = value;

      setFormProduct(payload);

      debouncedOnChangeText(option, param, value);
    }
  };

  // quantity
  const debouncedUpdateQuantity = useDebouncedCallback(
    (value: number | null) => {
      if (formProduct && value !== null && Number.isInteger(value)) {
        updateQuotationProduct({
          id: formProduct.id,
          quantity: value,
        });
      }
    },
    1000,
  );

  const updateQuantity = (value: number | null) => {
    if (formProduct && value) {
      const payload = { ...formProduct };

      payload.quantity = value;

      setFormProduct(payload);

      debouncedUpdateQuantity(value);
    }
  };

  useEffect(() => {
    if (isSuccessUpdateQuotationProductParam) {
      refetch();
    }
  }, [isSuccessUpdateQuotationProductParam]);

  useEffect(() => {
    if (isSuccessUpdateQuotationProduct) {
      refetch();
    }
  }, [isSuccessUpdateQuotationProduct]);

  return (
    <Col>
      {formProduct?.options.map((option, optionIndex) => (
        <Col key={`option-${option.id}`}>
          <Typography.Title level={4}>{option.name}</Typography.Title>
          <Row gutter={16}>
            {option.params.map((param, paramIndex) => (
              <Col key={`param-${param.id}`} span={8}>
                {param.type === 'list' && param.key && param.values && (
                  <Select
                    placeholder={param.name}
                    value={param.value}
                    onSelect={(selected: any) => updatePrice(option, param, selected, optionIndex, paramIndex)}
                    options={param.values.map(v => ({ value: v.id, label: v.description }))}
                    style={{ width: '100%' }}
                  />
                )}

                {['number', 'price'].includes(param.type) && (
                  <InputNumber
                    placeholder={param.name}
                    min={0}
                    style={{ width: '100%' }}
                    value={param.value as any}
                    onChange={value => onChangeNumber(option, param, value, optionIndex, paramIndex)}
                  />
                )}

                {param.type === 'text' && (
                  <Input
                    placeholder={param.name}
                    style={{ width: '100%', marginBottom: 16 }}
                    value={param.value as any}
                    onChange={e => onChangeText(option, param, e.target.value, optionIndex, paramIndex)}
                  />
                )}
              </Col>
            ))}
          </Row>
        </Col>
      ))}
      <Col key="product-quantity" span={8}>
        <Typography.Title level={4}>Quantità</Typography.Title>
        <InputNumber value={formProduct?.quantity} min={1} style={{ width: '100%' }} onChange={updateQuantity} />
      </Col>
    </Col>
  );
}
