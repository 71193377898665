import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { WorkstationType } from '@constants/WorkstationType';

interface ConfigState {
  workstationType: WorkstationType;
}

const initialState: ConfigState = {
  workstationType: WorkstationType.Admin,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setWorkstationType: (state, action: PayloadAction<WorkstationType>) => {
      state.workstationType = action.payload;
    },
  },
});

export const { setWorkstationType } = configSlice.actions;

export const workstationTypeConfigState = (state: RootState) => state.config.workstationType;

export default configSlice.reducer;
