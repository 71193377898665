import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { useAppDispatch } from '@hooks/redux';
import { openModal } from '@store/sources';
import { SourcesTable } from './SourcesTable';
import { CreateSourceModal } from './CreateSourceModal';
import { UpdateSourceModal } from './UpdateSourceModal';
import { DeleteSourceModal } from './DeleteSourceModal';


export function Sources() {
  const dispatch = useAppDispatch();

  const onNew = () => {
    dispatch(openModal('createSource'));
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Provenienze"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Provenienza</Button>}
        >
          <SourcesTable />
          <CreateSourceModal />
          <UpdateSourceModal />
          <DeleteSourceModal />
        </Card>
      </Col>
    </Row>
  );
}
