import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ListOption } from '@api/types';

function updateListOption({ id, option }: { id: number, option: Partial<ListOption> }) {
  return axios.put(`${apiBaseUrl}/list-options/${id}`, option);
}

export const useUpdateListOption = () => useMutation({
  mutationFn: updateListOption,
  mutationKey: [QueryKey.UpdateListOption],
});
