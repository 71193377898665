import { Col, Form, InputNumber, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  isEditIncomingMaterialModalOpenState,
  selectedIncomingMaterialState,
  setSelectedIncomingMaterial,
} from '@store/orders';
import { useEffect } from 'react';
import { useSetArrivedQuantity } from '@api/orders/useSetArrivedQuantity';
import { useGetIncomingOrder } from '@api/orders';

export function EditIncomingMaterial() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const open = useAppSelector(isEditIncomingMaterialModalOpenState);
  const selectedIncomingMaterial = useAppSelector(selectedIncomingMaterialState);

  const { refetch } = useGetIncomingOrder(selectedIncomingMaterial?.orderId ?? '');

  const { mutate, isSuccess, reset } = useSetArrivedQuantity();

  const onOk = () => {
    mutate({ ...selectedIncomingMaterial, ...form.getFieldsValue() });
  };

  const onCancel = () => {
    dispatch(closeModal('editIncomingMaterial'));
    dispatch(setSelectedIncomingMaterial(null));
  };

  useEffect(() => {
    const arrivedQuantity = selectedIncomingMaterial?.arrivedQuantity === null ? selectedIncomingMaterial.quantity : selectedIncomingMaterial?.arrivedQuantity;

    form.setFieldsValue({ arrivedQuantity });
  }, [selectedIncomingMaterial]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      dispatch(closeModal('editIncomingMaterial'));
      dispatch(setSelectedIncomingMaterial(null));
    }
  }, [isSuccess]);

  return (
    <Modal title="Modifica quantità arrivata" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="edit-incoming-material" layout="vertical">
          <Form.Item name="arrivedQuantity" label="Quantità Arrivata">
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
}
