import { OrderStatus } from '@api/types';

export function getOrderStatusLabel(status: OrderStatus) {
  switch (status) {
    case 'draft':
      return 'Bozza';
    case 'sent':
      return 'Inviato';
    case 'arrived':
      return 'Arrivato';
    default:
      return '';
  }
}
