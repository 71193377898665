import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { CreateSupplierRequest } from '@api/types';


function updateSupplier({ id, payload }: { id: string, payload: CreateSupplierRequest }) {
  return axios.put(`${apiBaseUrl}/${ApiPath.Suppliers}/${id}`, payload);
}

export function useUpdateSupplier() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, { id: string, payload: CreateSupplierRequest }>({
    mutationKey: [QueryKey.UpdateSupplier],
    mutationFn: updateSupplier,
  });
}
