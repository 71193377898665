import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Order } from '@api/types';

interface UpdateOrderRequest {
  orderId: string,
  order: Partial<Order>
}

export function useUpdateOrder() {
  return useMutation<AxiosResponse<Order>, AxiosError<{ message: string }>, UpdateOrderRequest>({
    mutationKey: [QueryKey.UpdateOrder],
    mutationFn: ({ orderId, order }: UpdateOrderRequest) => {
      return axios.put<Order>(`${apiBaseUrl}/${ApiPath.Orders}/${orderId}`, order);
    },
  });
}
