import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { CreateProductRequest, CreateProductResponse } from '@api/types';

async function createProduct(product: CreateProductRequest): Promise<CreateProductResponse> {
  const { data } = await axios.post<CreateProductResponse>(`${apiBaseUrl}/${ApiPath.Products}`, product);

  return data;
}

export const useCreateProduct = () => useMutation<CreateProductResponse, unknown, CreateProductRequest>({
  mutationFn: createProduct,
  mutationKey: [QueryKey.CreateProduct],
});
