import axios from 'axios';
import { IncomingOrderMaterials } from '@api/types';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';

export async function getIncomingOrder(id: string) {
  try {
    const response = await axios.get<IncomingOrderMaterials>(`${apiBaseUrl}/${ApiPath.IncomingOrders}/${id}`);

    return response?.data;
  } catch (err) {
    console.error('[getIncomingOrder]', err);
  }
}

export function useGetIncomingOrder(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetIncomingOrder, id],
    queryFn: () => getIncomingOrder(id),
    enabled: id !== '',
  });
}
