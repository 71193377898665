import { Button, Card, Col, Input, Row, Space, Table } from 'antd';
import { useGetWarehouseMaterials, useGetWarehouses } from '@api/warehouses';
import React, { useEffect, useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { AddWarehouseMaterialModal } from './components/AddWarehouseMaterialModal';
import { useNavigate } from 'react-router-dom';
import { WarehouseMaterials } from '@api/types';
import { WarehouseLoadingModal } from './components/WarehouseLoadingModal';
import { useAppDispatch } from '@hooks/redux';
import { openModal, setSelectedWarehouseId } from '@store/warehouse';

export function Warehouse() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [action, setAction] = useState<'load' | 'unload'>('load');
  const [warehouseMaterialId, setWarehouseMaterialId] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');

  const { data: warehouses, isSuccess: isSuccessLoadingWarehouses } = useGetWarehouses();
  const { data: materials } = useGetWarehouseMaterials(warehouses?.[0].id ?? '');

  const tableMaterials = materials?.filter(m => m.description.toLowerCase().includes(searchValue.toLowerCase())) ?? [];

  useEffect(() => {
    if (isSuccessLoadingWarehouses) {
      dispatch(setSelectedWarehouseId(warehouses?.[0].id));
    }
  }, [isSuccessLoadingWarehouses]);

  const onNew = () => {
    dispatch(openModal('addMaterial'));
  };

  const onEdit = (record: WarehouseMaterials) => {
    navigate(`/registry/materials/${record.materialId}`);
  };

  const onLoad = (material: WarehouseMaterials) => {
    setAction('load');
    setWarehouseMaterialId(material.id);
    dispatch(openModal('loadingMaterial'));
  };

  const onUnload = (material: WarehouseMaterials) => {
    setAction('unload');
    setWarehouseMaterialId(material.id);
    dispatch(openModal('loadingMaterial'));
  };

  const columns: ColumnProps<WarehouseMaterials>[] = [
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Totale',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Disponibili',
      dataIndex: 'available',
      key: 'available',
    },
    {
      title: 'Impegnati',
      dataIndex: 'busy',
      key: 'busy',
    },
    {
      title: 'In Arrivo',
      dataIndex: 'incoming',
      key: 'incoming',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onLoad(record)}>Carica</Button>
          <Button type="link" onClick={() => onUnload(record)}>Scarica</Button>
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Magazzino"
          extra={<Button type="primary" onClick={onNew}>Nuovo Materiale</Button>}
        >
          <Row justify="end" style={{ marginTop: '24px', marginBottom: '36px' }}>
            <Col>
              <Space size="middle">
                <Input.Search
                  placeholder="descrizione"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  // onSearch={onSearch}
                  enterButton
                  style={{ minWidth: '300px' }}
                />
                <Button onClick={() => setSearchValue('')}>Reset</Button>
              </Space>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={tableMaterials}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: tableMaterials.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
      <AddWarehouseMaterialModal />
      <WarehouseLoadingModal
        warehouseId={warehouses?.[0].id ?? ''}
        warehouseMaterialId={warehouseMaterialId}
        action={action}
      />
    </Row>
  );
}
