import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, theme } from 'antd';
import { RootRouter } from './RootRouter';
import './App.css';


const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          algorithm: [theme.defaultAlgorithm],
          // "components": {
          //   "Table": {
          //     "headerBg": "rgba(19,194,194,0.3)"
          //   }
          // }
        }}
      >
        <RootRouter />
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
