import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { apiBaseUrl } from '@constants/constants';

function deleteOrderMaterial({ orderId, materialId }: { orderId: string; materialId: string }) {
  return axios.delete(`${apiBaseUrl}/orders/${orderId}/materials/${materialId}`);
}

export function useDeleteOrderMaterial() {
  return useMutation({
    mutationKey: [QueryKey.DeleteOrderMaterial],
    mutationFn: deleteOrderMaterial,
  });
}
