import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { GetQuotationsRequest, Quotation } from '@api/types';


async function getAllQuotations(params?: GetQuotationsRequest): Promise<Quotation[]> {
  try {
    let response = await axios.get<Quotation[]>(`${apiBaseUrl}/quotations`, { params });

    return response.data || [];
  } catch (e) {
    return [];
  }
}

export function useGetQuotations(params?: GetQuotationsRequest) {
  return useQuery({
    queryKey: [QueryKey.GetQuotations, params],
    queryFn: () => getAllQuotations(params),
  });
}
