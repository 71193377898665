export function getCommissionStatusLabel(status: string) {
  switch (status) {
    case 'engineering':
      return 'Progettazione';
    case 'production':
      return 'In Produzione';
    case 'ready':
      return 'Pronta';
    case 'waiting-payment':
      return 'Attesa Pagamento';
    case 'shipping':
      return 'In Spedizione';
    case 'pick-up':
      return 'Ritiro';
    case 'evaded':
      return 'Evasa';
    default:
      return '';
  }
}
