import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Material } from '@api/types';

async function getMaterials(): Promise<Material[]> {
  const response = await axios.get<Material[]>(`${apiBaseUrl}/materials`);
  return response.data;
}

export function useGetMaterials() {
  return useQuery({
    queryKey: [QueryKey.GetMaterials],
    queryFn: getMaterials,
  });
}
