enum OrdersKeys {
  GetOrder = 'GetOrder',
  GetOrders = 'GetOrders',
  CreateOrder = 'CreateOrder',
  GetOrderMaterials = 'GetOrderMaterials',
  CreateOrderMaterial = 'CreateOrderMaterial',
  DeleteOrderMaterial = 'DeleteOrderMaterial',
  DownloadOrderPdf = 'DownloadOrderPdf',
  UpdateOrder = 'UpdateOrder',
  GetIncomingOrders = 'GetIncomingOrders',
  GetIncomingOrder = 'GetIncomingOrder',
  DeleteOrder = 'DeleteOrder',
  SetArrivedQuantity = 'SetArrivedQuantity',
}

enum Referrers {
  GetReferrers = 'GetReferrers',
}



export enum AllKey {
  // customers
  GetCustomers = 'GetCustomers',
  GetCustomer = 'GetCustomer',
  UpdateCustomer = 'UpdateCustomer',
  CreateCustomer = 'CreateCustomer',
  DeleteCustomer = 'DeleteCustomer',

  // products
  GetProducts = 'GetProducts',
  GetProduct = 'GetProduct',
  CreateProduct = 'CreateProduct',

  // quotations
  CreateQuotation = 'CreateQuotation',
  CreateQuotationProduct = 'CreateQuotationProduct',
  CreateQuotationSource = 'CreateQuotationSource',
  DeleteQuotationReferrer = 'DeleteQuotationReferrer',
  DeleteQuotationSource = 'DeleteQuotationSource',
  GetQuotation = 'GetQuotation',
  GetQuotationFiles = 'GetQuotationFiles',
  DeleteQuotationFile = 'DeleteQuotationFile',
  GetQuotationSources = 'GetQuotationSources',
  GetQuotationStatuses = 'GetQuotationStatuses',
  GetQuotations = 'GetQuotations',
  UpdateQuotation = 'UpdateQuotation',
  UpdateQuotationProduct = 'UpdateQuotationProduct',
  UpdateQuotationProductParam = 'UpdateQuotationProductParam',
  UpdateQuotationSource = 'UpdateQuotationSource',
  DownloadQuotationPdf = 'DownloadQuotationPdf',

  // commissions
  GetCommissions = 'GetCommissions',
  GetCommission = 'GetCommission',
  CreateCommission = 'CreateCommission',
  GetCommissionFiles = 'GetCommissionFiles',
  GetCommissionMaterials = 'GetCommissionMaterials',
  UpdateCommission = 'UpdateCommission',

  // list options
  GetListOptionTypes = 'GetListOptionTypes',
  GetListOptions = 'GetListOptions',
  UpdateListOption = 'UpdateListOption',

  // users
  GetProfile = 'GetProfile',
  GetUser = 'GetUser',
  GetUsers = 'GetUsers',
  UpdateUser = 'UpdateUser',
  UserChangePassword = 'UserChangePassword',
  GetUserDailyCheck = 'GetUserDailyCheck',
  UserCheckIn = 'UserCheckIn',
  UserCheckOut = 'UserCheckOut',
  TimeBreakStart = 'TimeBreakStart',
  TimeBreakEnd = 'TimeBreakEnd',

  // auth
  Register = 'Register',

  // work-orders
  GetWorkOrders = 'GetWorkOrders',
  GetWorkOrderById = 'GetWorkOrderById',
  CreateWorkOrder = 'CreateWorkOrder',
  WorkOrderAddCommission = 'WorkOrderAddCommission',
  WocActivateAllWorkPhases = 'WocActivateAllWorkPhases',
  WocUpdatePhase = 'WocUpdatePhase',
  StartWorkOrder = 'StartWorkOrder',
  GetWorkOrderByPhaseKey = 'GetWorkOrderByPhaseKey',
  GetWorkOrderByIdAndPhase = 'GetWorkOrderByIdAndPhase',
  WorkOrderCommissionPhase = 'WorkOrderCommissionPhase',
  GetCompletedWorkOrders = 'GetCompletedWorkOrders',
  GetWorkOrderPhaseCommission = 'GetWorkOrderPhaseCommission',

  // work-phases
  GetWorkPhases = 'GetWorkPhases',

  // warehouse
  GetWarehouses = 'GetWarehouses',
  GetWarehouseSupplies = 'GetWarehouseSupplies',
  AddWarehouseMaterial = 'AddWarehouseMaterial',
  WarehouseLoading = 'WarehouseLoading',

  // suppliers
  GetSupplier = 'GetSupplier',
  GetSuppliers = 'GetSuppliers',
  CreateSupplier = 'CreateSupplier',
  UpdateSupplier = 'UpdateSupplier',
  DeleteSupplier = 'DeleteSupplier',
  GetSupplierMaterials = 'GetSupplierMaterials',
  GetMaterialSuppliers = 'GetMaterialSuppliers',
  CreateSupplierMaterial = 'CreateSupplierMaterial',
  UpdateSupplierMaterial = 'UpdateSupplierMaterial',
  DeleteSupplierMaterial = 'DeleteSupplierMaterial',
  GetSupplierMaterial = 'GetSupplierMaterial',

  // materials
  GetMaterials = 'GetMaterials',
  GetMaterial = 'GetMaterial',
  UpdateMaterial = 'UpdateMaterial',

  // workers
  GetWorkers = 'GetWorkers',
}

export const QueryKey = { ...OrdersKeys, ...Referrers, ...AllKey };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type QueryKey = typeof QueryKey;
