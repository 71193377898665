import axios from 'axios';
import { IncomingOrder } from '@api/types';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';

export async function getIncomingOrders() {
  try {
    const response = await axios.get<IncomingOrder[]>(`${apiBaseUrl}/${ApiPath.IncomingOrders}`);

    return response.data;
  } catch (err) {
    console.error('[getIncomingOrders]', err);
  }
}

export function useGetIncomingOrders() {
  return useQuery({
    queryKey: [QueryKey.GetIncomingOrders],
    queryFn: getIncomingOrders,
  });
}
