import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Commission } from '@api/types';

async function getCommissions(q?: string): Promise<Commission[]> {
  const response = await axios.get<Commission[]>(`${apiBaseUrl}/${ApiPath.Commissions}?q=${q ?? ''}`);
  return response.data;
}

export function useGetCommissions(q?: string) {
  return useQuery({
    queryKey: [QueryKey.GetCommissions, q],
    queryFn: () => getCommissions(q),
  });
}
