import React, { useEffect } from 'react';
import { Alert, Col, Modal, Typography } from 'antd';
import { useDeleteSupplierMaterial } from '@api/suppliers/useDeleteSupplierMaterial';
import { useGetSupplierMaterials } from '@api/suppliers/useGetSupplierMaterials';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isDeleteSupplierMaterialModalOpenState } from '@store/suppliers';

export interface Props {
  supplierMaterialId: string;
  supplierId: string;
}

export function DeleteSupplierMaterialModal({ supplierId, supplierMaterialId }: Props) {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isDeleteSupplierMaterialModalOpenState);

  const { refetch } = useGetSupplierMaterials(supplierId);

  const { mutate, isSuccess, error, reset } = useDeleteSupplierMaterial();

  useEffect(() => {
    if (isSuccess) {
      refetch().then(() => {
        reset();
        dispatch(closeModal('deleteSupplierMaterial'));
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    mutate(supplierMaterialId);
  };

  const onCancel = () => {
    reset();
    dispatch(closeModal('deleteSupplierMaterial'));
  };

  return (
    <Modal title="Elimina Materiale" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Typography>Sei sicuro di voler eliminare il seguente materiale?</Typography>

        <Typography.Title level={5} style={{ textAlign: 'center' }}></Typography.Title>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
