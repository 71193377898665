import { Commission } from '@api/types';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

async function updateCommission(commission: Pick<Commission, 'id' | 'note'>) {
  try {
    await axios.put(`${apiBaseUrl}/${ApiPath.Commissions}/${commission.id}`, commission);
  } catch (err) {
    console.error(err);
  }
}

export function useUpdateCommission() {
  return useMutation({
    mutationKey: [QueryKey.UpdateCommission],
    mutationFn: updateCommission,
  });
}
