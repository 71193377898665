import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Source } from '@api/types';
import { RootState } from '@store/store';

interface SourcesModals {
  createSource: boolean;
  updateSource: boolean;
  deleteSource: boolean;
}

interface SourcesState {
  modals: SourcesModals;
  selectedSource: Source | null;
}

const initialState: SourcesState = {
  modals: {
    createSource: false,
    updateSource: false,
    deleteSource: false,
  },
  selectedSource: null,
};

export const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },
    setSelectedSource: (state, action: PayloadAction<Source | null>) => {
      state.selectedSource = action.payload;
    },
  },
});

export const { openModal, closeModal, setSelectedSource } = sourcesSlice.actions;

export const isCreateSourceModalOpenState = (state: RootState) => state.sources.modals.createSource;
export const isUpdateSourceModalOpenState = (state: RootState) => state.sources.modals.updateSource;
export const isDeleteSourceModalOpenState = (state: RootState) => state.sources.modals.deleteSource;

export const selectedSourceState = (state: RootState) => state.sources.selectedSource;

export default sourcesSlice.reducer;
