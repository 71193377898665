import { Button, Card, Col, Row } from 'antd';
import { CreateCommissionModal } from './components/CreateCommissionModal';
import { CommissionsTable } from './components/CommissionsTable';
import { useAppDispatch } from '@hooks/redux';
import { openModal } from '@store/commissions';

export function Commissions() {
  const dispatch = useAppDispatch();

  const onNew = () => dispatch(openModal('createCommission'));

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Commesse"
          extra={<Button type="primary" onClick={onNew}>Nuova Commessa</Button>}
        >
          <CommissionsTable />
        </Card>
      </Col>
      <CreateCommissionModal />
    </Row>
  );
}
