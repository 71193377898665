export function getQuotationStatusLabel(status: string) {
  switch (status) {
    case 'draft':
      return 'Bozza';
    case 'sent':
      return 'Inviato';
    case 'confirmed':
      return 'Confermato';
    case 'waiting':
      return 'Attesa Acconto';
    case 'production':
      return 'Messo In Produzione';
    default:
      return '';
  }
}
