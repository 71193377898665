import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Source } from '@api/types';

function createQuotationSource(source: Partial<Source>) {
  return axios.post(`${apiBaseUrl}/${ApiPath.QuotationSources}`, source);
}

export function useCreateQuotationSource() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, Partial<Source>>({
    mutationKey: [QueryKey.CreateQuotationSource],
    mutationFn: createQuotationSource,
  });
}
