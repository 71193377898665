import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Warehouse, WarehouseLoadingRequest, WarehouseMaterials } from '@api/types';

async function getWarehouses(): Promise<Warehouse[]> {
  const response = await axios.get<Warehouse[]>(`${apiBaseUrl}/${ApiPath.Warehouses}`);
  return response.data;
}

async function getWarehouseMaterials(warehouseId: string) {
  const response = await axios.get<WarehouseMaterials[]>(`${apiBaseUrl}/${ApiPath.Warehouses}/${warehouseId}/materials`);
  return response.data;
}

function addWarehouseMaterial(payload: { warehouseId: string, description: string }) {
  return axios.post(`${apiBaseUrl}/${ApiPath.Warehouses}/${payload.warehouseId}/materials`, { description: payload.description });
}

export function useGetWarehouses() {
  return useQuery({
    queryKey: [QueryKey.GetWarehouses],
    queryFn: getWarehouses,
  });
}

export function useGetWarehouseMaterials(warehouseId: string) {
  return useQuery({
    queryKey: [QueryKey.GetWarehouseSupplies, warehouseId],
    queryFn: () => getWarehouseMaterials(warehouseId),
    enabled: warehouseId !== '',
  });
}

export function useAddWarehouseMaterial() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, { warehouseId: string, description: string }>({
    mutationKey: [QueryKey.AddWarehouseMaterial],
    mutationFn: addWarehouseMaterial,
  });
}

export function useWarehouseLoading() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, WarehouseLoadingRequest>({
    mutationKey: [QueryKey.WarehouseLoading],
    mutationFn: (payload: WarehouseLoadingRequest) => {
      return axios.post(`${apiBaseUrl}/warehouses/loading`, payload);
    },
  });
}
