import { Alert, Col, Form, Input, InputNumber, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { useGetSupplierMaterials } from '@api/suppliers/useGetSupplierMaterials';
import { useGetMaterials } from '@api/materials/useGetMaterials';
import { useCreateSupplierMaterial } from '@api/suppliers/useCreateSupplierMaterial';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isAddSupplierMaterialModalOpenState } from '@store/suppliers';

interface Props {
  supplierId: string;
}

export function AddSupplierMaterialModal({ supplierId }: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isAddSupplierMaterialModalOpenState);

  const { data: supplierMaterials, isLoading: isLoadingSuppliers } = useGetMaterials();
  const { refetch: refetchOrderMaterials } = useGetSupplierMaterials(supplierId);

  const { mutate: addMaterial, isSuccess, reset, error } = useCreateSupplierMaterial();

  useEffect(() => {
    if (isSuccess) {
      refetchOrderMaterials().then(() => {
        reset();
        form.resetFields();
        dispatch(closeModal('addSupplierMaterial'));
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    form.validateFields()
      .then(() => {
        addMaterial({ supplierId, ...form.getFieldsValue() });
      })
      .catch((err => {
      }));
  };

  const onCancel = () => {
    reset();
    form.resetFields();
    dispatch(closeModal('addSupplierMaterial'));
  };

  if (isLoadingSuppliers) {
    return null;
  }

  return (
    <Modal title="Aggiungi Materiale" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="add-supplier-material" layout="vertical">
          <Form.Item
            name="materialId"
            label="Materiale"
            required
            rules={[{ required: true, message: 'Per favore seleziona un materiale' }]}
          >
            <Select options={supplierMaterials?.map(s => ({ label: s.description, value: s.id }))} />
          </Form.Item>

          <Form.Item
            name="code"
            label="Codice"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="price"
            label="Prezzo"
          >
            <InputNumber style={{ width: '100%' }} addonAfter="€" />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
