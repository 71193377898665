import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { EntityFile } from '../types';

async function getQuotationFiles(quotationId: number): Promise<EntityFile[]> {
  const response = await axios.get<EntityFile[]>(`${apiBaseUrl}/quotations/${quotationId}/files`);

  return response.data;
}

export function useGetQuotationFiles(quotationId: number) {
  return useQuery({
    queryKey: [QueryKey.GetQuotationFiles, quotationId],
    queryFn: () => getQuotationFiles(quotationId),
    enabled: quotationId > 0,
  });
}
