import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface CommissionsModals {
  createCommission: boolean;
}

interface CommissionsState {
  modals: CommissionsModals;
}

const initialState: CommissionsState = {
  modals: {
    createCommission: false,
  },
};


export const commissionsSlice = createSlice({
  name: 'commissions',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },
  },
});

export const { openModal, closeModal } = commissionsSlice.actions;

export const isCreateCommissionModalOpenState = (state: RootState) => state.commissions.modals.createCommission;

export default commissionsSlice.reducer;
