import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { QueryKey } from '@constants/queries';
import { Quotation } from '@api/types';

async function downloadQuotationPdf(quotation: Quotation | null | undefined): Promise<void> {
  const response = await axios.get(`${apiBaseUrl}/${ApiPath.Quotations}/${quotation?.id}/pdf`, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `preventivo-${quotation?.year}-${quotation?.serial}.pdf`);
  document.body.appendChild(link);
  link.click();
}

export function useDownloadQuotationPdf() {
  return useMutation({
    mutationKey: [QueryKey.DownloadQuotationPdf],
    mutationFn: downloadQuotationPdf,
  });
}
