import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { EntityFile } from '@api/types';

async function getCommissionFiles(id: string): Promise<EntityFile[]> {
  const response = await axios.get<EntityFile[]>(`${apiBaseUrl}/${ApiPath.Commissions}/${id}/files`);
  return response.data;
}

export function useGetCommissionFiles(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetCommissionFiles, id],
    queryFn: () => getCommissionFiles(id),
    enabled: id !== '',
  });
}
