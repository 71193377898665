import React from 'react';
import { Button, Space, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useGetQuotationSources } from '@api/quotations/useGetQuotationSources';
import { Source } from '@api/types';
import { useAppDispatch } from '@hooks/redux';
import { openModal, setSelectedSource } from '@store/sources';

export function SourcesTable() {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetQuotationSources();

  const onEdit = (source: Source) => {
    dispatch(setSelectedSource(source));
    dispatch(openModal('updateSource'));
  };

  const onDelete = (source: Source) => {
    dispatch(setSelectedSource(source));
    dispatch(openModal('deleteSource'));
  };

  const columns: ColumnProps<Source>[] = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: data?.length ?? 0,
        }}
        rowKey="id"
        size="small"
      />
    </Spin>
  );
}
