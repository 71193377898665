import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { Supplier } from '@api/types';

interface SupplierModals {
  createSupplier: boolean;
  deleteSupplier: boolean;
  addSupplierMaterial: boolean;
  updateSupplierMaterial: boolean;
  deleteSupplierMaterial: boolean;
}

interface SuppliersState {
  modals: SupplierModals;
  selectedSupplier: Supplier | null;
}

const initialState: SuppliersState = {
  modals: {
    createSupplier: false,
    deleteSupplier: false,
    addSupplierMaterial: false,
    updateSupplierMaterial: false,
    deleteSupplierMaterial: false,
  },
  selectedSupplier: null,
};

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },

    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },

    setSelectedSupplier: (state, action: PayloadAction<Supplier | null>) => {
      state.selectedSupplier = action.payload;
    },
  },
});

export const { openModal, closeModal, setSelectedSupplier } = suppliersSlice.actions;

export const isCreateSupplierModalOpenState = (state: RootState) => state.suppliers.modals.createSupplier;
export const isDeleteSupplierModalOpenState = (state: RootState) => state.suppliers.modals.deleteSupplier;
export const isAddSupplierMaterialModalOpenState = (state: RootState) => state.suppliers.modals.addSupplierMaterial;
export const isUpdateSupplierMaterialModalOpenState = (state: RootState) => state.suppliers.modals.updateSupplierMaterial;
export const isDeleteSupplierMaterialModalOpenState = (state: RootState) => state.suppliers.modals.deleteSupplierMaterial;

export const selectedSupplierState = (state: RootState) => state.suppliers.selectedSupplier;

export default suppliersSlice.reducer;
