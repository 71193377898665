import axios from 'axios';
import { Order } from '@api/types';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';

async function getOrders() {
  try {
    const result = await axios.get<Order[]>(`${apiBaseUrl}/${ApiPath.Orders}`);

    return result.data;
  } catch (err) {
    console.error('[getIncomingOrders]', err);
  }
}


export function useGetOrders() {
  return useQuery({
    queryKey: [QueryKey.GetOrders],
    queryFn: getOrders,
  });
}
