import { Col, Row } from 'antd';
import { useGetCompletedWorkOrders } from '@api/work-orders';

export function CompletedOrders() {
  const { data } = useGetCompletedWorkOrders();

  console.log(data);

  return (
    <Row>
      <Col span={24}>

      </Col>
    </Row>
  )
}
