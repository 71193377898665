import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { QueryKey } from '@constants/queries';


export function deleteCustomer(id: string) {
  return axios.delete(`${apiBaseUrl}/customers/${id}`);
}

export function useDeleteCustomer() {
  return useMutation<AxiosResponse, AxiosError<{ message: string[] }>, string>({
    mutationKey: [QueryKey.DeleteCustomer],
    mutationFn: deleteCustomer,
  });
}
