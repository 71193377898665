import { Button, Card, Col, Row, Space, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { ColumnProps } from 'antd/es/table';
import { useGetQuotations } from '@api/quotations/useGetQuotations';
import { useNavigate } from 'react-router-dom';
import { useCreateQuotation } from '@api/quotations/useCreateQuotation';
import { getQuotationStatusLabel } from './utils';
import { Quotation } from '@api/types';

export function Quotations() {
  const navigate = useNavigate();
  const { data } = useGetQuotations();

  const { mutate: createQuotation, isSuccess, data: createQuotationData } = useCreateQuotation();

  const onEdit = (id: number) => {
    navigate(`/quotes/${id}`);
  };

  const onDelete = (id: number) => {
    console.log('delete', id);
  };

  const onNew = () => {
    createQuotation();
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/quotes/${createQuotationData.data.id}`);
    }
  }, [isSuccess]);

  const columns: ColumnProps<Quotation>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Commessa',
      dataIndex: 'commission',
      key: 'commission',
      render: (_, record) => record.commission ?
        <Typography>{record.commission.year}-{record.commission.serial}</Typography> : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Typography>{getQuotationStatusLabel(text)}</Typography>,
    },
    {
      title: 'Cliente',
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) =>
        <Typography>{customer?.firstName ?? ''} {customer?.lastName ?? ''} {customer?.name ?? ''}</Typography>,
    },
    {
      title: 'Segnalatore',
      dataIndex: 'referrer',
      key: 'referrer',
      render: (referrer) => <Typography>{referrer?.description ?? ''}</Typography>,
    },
    {
      title: 'Provenienza',
      dataIndex: 'source',
      key: 'source',
      render: (source) => <Typography>{source?.description ?? ''}</Typography>,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Preventivi"
          extra={<Button type="primary" onClick={onNew}>Nuovo Preventivo</Button>}
        >
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={data}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: data?.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
    </Row>
  );
}
