import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { apiBaseUrl } from '@constants/constants';

function deleteOrder(id: string) {
  return axios.delete(`${apiBaseUrl}/orders/${id}`);
}

export function useDeleteOrder() {
  return useMutation({
    mutationKey: [QueryKey.DeleteOrder],
    mutationFn: deleteOrder,
  });
}
