import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Order } from '@api/types';

async function getOrder(id: string): Promise<Order> {
  const response = await axios.get<Order>(`${apiBaseUrl}/${ApiPath.Orders}/${id}`);
  return response.data;
}

export function useGetOrder(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetOrder, id],
    queryFn: () => getOrder(id),
    enabled: id !== '',
  });
}
