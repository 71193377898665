import { Alert, Col, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useGetQuotationSources } from '@api/quotations';
import { useCreateQuotationSource } from '@api/quotations/useCreateQuotationSource';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isCreateSourceModalOpenState } from '@store/sources';


export function CreateSourceModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isCreateSourceModalOpenState);

  const { refetch: refetchQuotationSources } = useGetQuotationSources();
  const { mutate: createQuotationSource, isSuccess, error, reset } = useCreateQuotationSource();

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationSources().then(() => {
        reset();
        form.resetFields();
        dispatch(closeModal('createSource'));
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    createQuotationSource({ ...form.getFieldsValue() });
  };

  const onCancel = () => {
    reset();
    form.resetFields();
    dispatch(closeModal('createSource'));
  };

  return (
    <Modal title="Crea Provenienza" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-source" layout="vertical">
          <Form.Item name="description" label="Descrizione">
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-source-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
