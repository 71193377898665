import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Order, OrderMaterial } from '@api/types';

type UpdateOrderMaterialRequest = Partial<OrderMaterial>;

export function useUpdateOrderMaterial() {
  return useMutation<AxiosResponse<Order>, AxiosError<{ message: string }>, UpdateOrderMaterialRequest>({
    mutationKey: [QueryKey.UpdateOrder],
    mutationFn: (orderMaterial: UpdateOrderMaterialRequest) => {
      return axios.put<Order>(`${apiBaseUrl}/${ApiPath.Orders}/${orderMaterial.orderId}/materials/${orderMaterial.id}`, orderMaterial);
    },
  });
}
