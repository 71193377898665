import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { SetArrivedQuantity } from '@api/types';
import { ApiPath } from '@constants/index';

async function setArrivedQuantity({ orderId, id, arrivedQuantity }: SetArrivedQuantity) {
  try {
    const response = await axios.put(`${apiBaseUrl}/${ApiPath.IncomingOrders}/${orderId}/materials/${id}/arrived-quantity`, { arrivedQuantity });

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export function useSetArrivedQuantity() {
  return useMutation({
    mutationKey: [QueryKey.SetArrivedQuantity],
    mutationFn: setArrivedQuantity,
  });
}
