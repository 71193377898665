import { Avatar, Breadcrumb, Card, Col, Empty, Row, Table, theme, Typography } from 'antd';
import { getWorkOrderStatusLabel } from '@routes/work-orders/utils';
import { useGetWorkOrderByPhaseKey } from '@api/work-orders';
import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { WorkOrder } from '@api/types';
import { useNavigate } from 'react-router-dom';

interface Props {
  workPhaseKey: string;
}

export function WorkOrdersPhase({ workPhaseKey }: Props) {
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { data: workOrders } = useGetWorkOrderByPhaseKey(workPhaseKey);

  const onShow = (id: string) => {
    navigate(`/${workPhaseKey}/${id}`);
  };

  const items = [
    { title: getWorkOrderStatusLabel(workPhaseKey) },
  ];

  const columns: ColumnProps<WorkOrder>[] = [
    {
      title: 'Stato',
      key: 'status',
      render: (_, record) => <Avatar size={14} style={{ backgroundColor: record.commissionsCompleted === 0 ? token.colorFill : record.commissionsCount === record.commissionsCompleted ? token.green : token.colorWarningText }} />,
    },
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>WO{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Commesse Totali',
      dataIndex: 'commissionsCount',
      key: 'commissionsCount'
    },
    {
      title: 'Commesse Completate',
      dataIndex: 'commissionsCompleted',
      key: 'commissionsCompleted'
    }
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title={<Breadcrumb items={items} />}>
          <Table
            columns={columns}
            dataSource={workOrders}
            onRow={(row) => {
              return {
                onClick: () => onShow(row.id),
                style: { cursor: 'pointer' },
              };
            }}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: workOrders?.length ?? 0,
            }}
            rowKey="id"
            size="large"
          />
        </Card>
      </Col>
    </Row>
  );
}
