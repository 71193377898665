import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { ReferrersTable } from './ReferrersTable';

export function Referrers() {
  const onNew = () => {
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Segnalatori"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Segnalatore</Button>}
        >
          <ReferrersTable />
        </Card>
      </Col>
    </Row>
  );
}
