export function getWorkOrderStatusLabel(status: string) {
  switch (status) {
    case 'engineering':
      return 'Progettazione';
    case 'laser-cut':
      return 'Taglio Laser';
    case 'folding':
      return 'Piegatura';
    case 'welding':
      return 'Saldatura';
    case 'assembly':
      return 'Assemblaggio';
    case 'painting':
      return 'Verniciatura';
    case 'packaging':
      return 'Confezionamento';
    case 'ready-for-delivery':
      return 'Pronto Alla Spedizione';
    case 'delivered':
      return 'spedito';
    default:
      return '';
  }
}
