import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { UpdateQuotationRequest } from '@api/types';

function updateQuotation(quotation: Partial<UpdateQuotationRequest>) {
  return axios.put(`${apiBaseUrl}/quotations`, quotation);
}

export const useUpdateQuotation = () => useMutation({
  mutationFn: updateQuotation,
  mutationKey: [QueryKey.UpdateQuotation],
});
