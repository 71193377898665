import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

function deleteSupplierMaterial(id: string) {
  return axios.delete(`${apiBaseUrl}/${ApiPath.SupplierMaterials}/${id}`);
}

export function useDeleteSupplierMaterial() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, string>({
    mutationKey: [QueryKey.DeleteSupplierMaterial],
    mutationFn: deleteSupplierMaterial,
  });
}
