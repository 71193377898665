import { Alert, Col, Form, InputNumber, Modal } from 'antd';
import { useGetWarehouseMaterials, useGetWarehouses, useWarehouseLoading } from '@api/warehouses';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isLoadingWarehouseMaterialModalOpenState } from '@store/warehouse';

export interface WarehouseLoadingModalProps {
  warehouseId: string;
  warehouseMaterialId: string;
  action: 'load' | 'unload';
}

export function WarehouseLoadingModal({ action, warehouseId, warehouseMaterialId }: WarehouseLoadingModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const open = useAppSelector(isLoadingWarehouseMaterialModalOpenState);

  const { data: warehouses } = useGetWarehouses();
  const { refetch } = useGetWarehouseMaterials(warehouses?.[0].id ?? '');

  const { mutate, isSuccess, error, reset } = useWarehouseLoading();

  const onOk = () => {
    const quantity = form.getFieldValue('quantity');

    if (quantity) {
      mutate({ warehouseId, warehouseMaterialId, action, quantity });
    }
  };

  const onCancel = () => {
    dispatch(closeModal('loadingMaterial'));
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      dispatch(closeModal('loadingMaterial'));
    }
  }, [isSuccess]);

  return (
    <Modal title="Aggiungi Materiale" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="load-material" layout="vertical">
          <Form.Item
            name="quantity"
            label="Quantità"
            initialValue={1}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
