import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { SelectListOptions } from '@api/types';

export const fetchListOptions = async (key: string): Promise<SelectListOptions[]> => {
  try {
    const response = await axios.get<SelectListOptions[]>(`${apiBaseUrl}/list-options/${key}`);

    return response.data || [];
  } catch (error) {
    console.error('[get-customers]', error);
    return [];
  }
};

export function useGetListOptions(key: string) {
  return useQuery({
    queryKey: [QueryKey.GetListOptions, key],
    queryFn: () => fetchListOptions(key),
    enabled: key !== '',
  });
}
