import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Col, Layout, Row, Space, theme, Typography } from 'antd';
import { SideNavigation } from '@components/sidenav/SideNavigation';
import { useAppSelector } from '@hooks/redux';
import { userProfileState } from '@store/user';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const { Content, Header } = Layout;
const { useToken } = theme;

export function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const { token } = useToken();
  const profile = useAppSelector(userProfileState);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SideNavigation collapsed={collapsed} toggle={toggle} />

      <Layout style={{ backgroundColor: token.colorBgBase }}>
        <Header style={{ backgroundColor: token.colorBgBase, padding: '0 16px' }}>
          <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
            <Col lg={0} style={{ display: 'flex' }}>
              <Button onClick={toggle} type="text">
                {collapsed ? <MenuUnfoldOutlined style={{ fontSize: 24 }} /> :
                  <MenuFoldOutlined style={{ fontSize: 24 }} />}
              </Button>
            </Col>

            <Space size="middle">
              <Typography>{`${profile?.name ? profile.name.toUpperCase() + ' ' : ''}${profile?.surname.toUpperCase() ?? ''}`}</Typography>

              {/*<Avatar style={{ backgroundColor: '#7265e6', verticalAlign: 'middle' }} size="large" gap={3}>*/}
              {/*  {`${profile?.name[0].toUpperCase() ?? ''}${profile?.surname[0].toUpperCase() ?? ''}`}*/}
              {/*</Avatar>*/}
            </Space>
          </Row>
        </Header>
        <Content
          style={{
            padding: '24px 16px 0',
            overflow: 'initial',
            borderTopLeftRadius: 8,
            backgroundColor: token.colorBgLayout,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
