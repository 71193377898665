import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Layout, Menu, Row, theme, Typography } from 'antd';
import { MenuProps } from 'antd/lib';
import {
  BellOutlined,
  DashboardOutlined,
  GroupOutlined,
  LoginOutlined,
  MessageOutlined,
  PrinterOutlined,
  SettingOutlined,
  ToolOutlined, UserOutlined,
} from '@ant-design/icons';
import logo from '../../img/logo.png';
import { WorkstationType } from '@constants/WorkstationType';
import { useAppSelector } from '@hooks/redux';
import { userRolesState } from '@store/user';
import { workstationTypeConfigState } from '@store/config';


const { Sider } = Layout;

const dashboardItem = {
  key: '/',
  label: <Link to="/">Dashboard</Link>,
  icon: React.createElement(DashboardOutlined),
};

const registryItem = {
  key: '/registry',
  label: <Typography>Anagrafiche</Typography>,
  icon: React.createElement(PrinterOutlined),
  children: [
    { label: <Link to="/registry/products">Prodotti</Link>, key: '/registry/products' },
    { label: <Link to="/registry/customers">Clienti</Link>, key: '/registry/customers' },
    { label: <Link to="/registry/suppliers">Fornitori</Link>, key: '/registry/suppliers' },
    { label: <Link to="/registry/materials">Materiali</Link>, key: '/registry/materials' },
    { label: <Link to="/registry/referrers">Segnalatori</Link>, key: '/registry/referrers' },
    { label: <Link to="/registry/sources">Provenienze</Link>, key: '/registry/sources' },
    { label: <Link to="/registry/price-list">Listino Prezzi</Link>, key: '/registry/price-list' },
  ],
};

const metalSheetQuotesItem = {
  key: '/metal-sheet-quotes',
  label: <Link to="/metal-sheet-quotes">Preventivi Lamiere</Link>,
  icon: React.createElement(PrinterOutlined),
};

const doorQuotesItem = {
  key: '/quotes',
  label: <Link to="/quotes">Preventivi Porte</Link>,
  icon: React.createElement(PrinterOutlined),
};

const commissionsItem = {
  key: '/commissions',
  label: <Link to="/commissions">Commesse</Link>,
  icon: React.createElement(PrinterOutlined),
};

const ordersItem = {
  key: '/orders',
  label: <Link to="/orders">Ordini a Fornitore</Link>,
  icon: React.createElement(PrinterOutlined),
};

const incomingOrders = {
  key: '/incoming-orders',
  label: <Link to="/incoming-orders">Ordini in Arrivo</Link>,
  icon: React.createElement(PrinterOutlined),
};

const warehouseItem = {
  key: '/warehouse',
  label: <Link to="/warehouse">Magazzino</Link>,
  icon: React.createElement(GroupOutlined),
};

const workOrdersItem = {
  key: '/work-orders',
  label: <Link to="/work-orders">Ordini di Lavoro</Link>,
  icon: React.createElement(GroupOutlined),
};

const laserCutItem = {
  key: '/laser-cut',
  label: <Link to="/laser-cut">Taglio Laser</Link>,
  icon: React.createElement(ToolOutlined),
};

const bendingItem = {
  key: '/folding',
  label: <Link to="/folding">Piegatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const weldingItem = {
  key: '/welding',
  label: <Link to="/welding">Saldatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const paintingItem = {
  key: '/painting',
  label: <Link to="/painting">Verniciatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const assemblyItem = {
  key: '/assembly',
  label: <Link to="/assembly">Assemblaggio</Link>,
  icon: React.createElement(ToolOutlined),
};

const settingsItem = {
  key: '/settings',
  label: <Link to="/settings">Settings</Link>,
  icon: React.createElement(SettingOutlined),
};

const logoutItem = {
  key: '/logout',
  label: <Link to="/logout">Logout</Link>,
  icon: React.createElement(LoginOutlined),
};

const notificationsItem = {
  key: '/notifications',
  label: <Link to="/notifications">Notifiche <Badge count={4} /></Link>,
  icon: React.createElement(BellOutlined),
};

const messagesItem = {
  key: '/messages',
  label: <Link to="/messages">Messaggi <Badge count={2} /></Link>,
  icon: React.createElement(MessageOutlined),
};

const workersItem = {
  key: '/workers',
  label: <Link to="/workers">Lavoratori</Link>,
  icon: React.createElement(UserOutlined),
};

export const SideNavigation = ({ collapsed, toggle }: { collapsed: boolean, toggle: () => void }) => {
  const location = useLocation();
  const userRoles = useAppSelector(userRolesState);
  const workstationType = useAppSelector(workstationTypeConfigState);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items: MenuProps['items'] = useMemo(() => {
    const items = [dashboardItem];

    if (workstationType === WorkstationType.Admin && userRoles.some(r => r.role === 'admin')) {
      items.push(doorQuotesItem);
      items.push(metalSheetQuotesItem);
      items.push(commissionsItem);
      items.push(workOrdersItem);
      items.push(warehouseItem);
      items.push(ordersItem);
      items.push(notificationsItem);
      items.push(messagesItem);
    }

    if (userRoles.some(r => ['admin', 'worker'].includes(r.role))) {
      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Assembly) {
        items.push(incomingOrders);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.LaserCut) {
        items.push(laserCutItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Bending) {
        items.push(bendingItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Welding) {
        items.push(weldingItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Assembly) {
        items.push(paintingItem);
        items.push(assemblyItem);
      }
    }

    if (workstationType === WorkstationType.Admin && userRoles.some(r => r.role === 'admin')) {
      items.push(workersItem);
      items.push(registryItem);
    }

    items.push(settingsItem);
    items.push(logoutItem);

    return items;
  }, [userRoles, workstationType]);

  return (
    <Sider
      style={{ background: colorBgContainer }}
      breakpoint="lg"
      collapsedWidth="0"
      collapsible
      collapsed={collapsed}
      // onClick={toggle}
      onBreakpoint={broken => console.log(broken)}
      onCollapse={(collapsed, type) => console.log(collapsed, type)}
      trigger={null}
    >
      <Row justify="center" style={{ margin: '24px 0' }}>
        <img width={160} src={logo} alt="logo abruzzo lamiere" />
      </Row>

      <Menu mode="inline" selectedKeys={[location.pathname]} items={items} style={{ border: 'none' }} />
    </Sider>
  );
};
