import { Form, Input, InputNumber, Modal } from 'antd';
import { useUpdateSupplierMaterial } from '@api/suppliers/useUpdateSupplierMaterial';
import { useEffect } from 'react';
import { useGetSupplierMaterials } from '@api/suppliers/useGetSupplierMaterials';
import { useGetSupplierMaterial } from '@api/suppliers/useGetSupplierMaterial';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isUpdateSupplierMaterialModalOpenState } from '@store/suppliers';

interface Props {
  supplierId: string;
  supplierMaterialId: string;
}

export function UpdateSupplierMaterialModal({ supplierId, supplierMaterialId }: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isUpdateSupplierMaterialModalOpenState);

  const { data, isLoading: isLoadingSupplyMaterial } = useGetSupplierMaterial(supplierMaterialId);
  const { refetch } = useGetSupplierMaterials(supplierId);
  const { mutate, isSuccess, reset } = useUpdateSupplierMaterial();

  const onCancel = () => {
    dispatch(closeModal('updateSupplierMaterial'));
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields()
      .then(() => {
        mutate({ id: supplierMaterialId, payload: form.getFieldsValue() });
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      form.resetFields();
      refetch();
      dispatch(closeModal('updateSupplierMaterial'));
    }
  }, [isSuccess]);

  if (isLoadingSupplyMaterial) {
    return null;
  }

  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} title="Modifica Fornitura">
      <Form form={form} layout="vertical" initialValues={data}>
        <Form.Item name="code" label="Codice">
          <Input />
        </Form.Item>

        <Form.Item name="price" label="Prezzo">
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
