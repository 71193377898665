import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { WorkOrder, WorkOrderCommission } from '@api/types';

async function simpleGet<T>(url: string) {
  const response = await axios.get<T>(url);

  return response.data;
}

async function getWorkOrders(): Promise<WorkOrder[]> {
  const response = await axios.get<WorkOrder[]>(`${apiBaseUrl}/${ApiPath.WorkOrders}`);

  return response.data;
}

async function getWorkOrderById(id: string): Promise<WorkOrder> {
  const response = await axios.get<WorkOrder>(`${apiBaseUrl}/${ApiPath.WorkOrders}/${id}`);

  return response.data;
}

async function createWorkOrder(): Promise<WorkOrder> {
  const response = await axios.post<WorkOrder>(`${apiBaseUrl}/${ApiPath.WorkOrders}`);

  return response.data;
}

async function addCommission({ workOrderId, commissionId }: { workOrderId: string, commissionId: string }) {
  const response = await axios.post(`${apiBaseUrl}/${ApiPath.WorkOrders}/${workOrderId}/commissions`, { commissionId });

  return response.data;
}

async function wocActivateAllWorkPhases({ woId, wocId }: { woId: string, wocId: string }) {
  try {
    const response = await axios.post(`${apiBaseUrl}/${ApiPath.WorkOrders}/${woId}/commissions/${wocId}/activate-all-work-phases`);
    console.log('wocActivateAllWorkPhases', response);
  } catch (err) {
    console.error(err);
  }
}

async function wocUpdatePhase(payload: { action: 'add' | 'remove', wocId: string, wpId: string }) {
  try {
    const response = await axios.put(`${apiBaseUrl}/${ApiPath.WorkOrders}/commission-work-phase`, payload);
    console.log('wocUpdatePhase', response);
  } catch (err) {
    console.error(err);
  }
}

async function getWorkOrderByPhaseKey(key: string) {
  try {
    const response = await axios.get<WorkOrder[]>(`${apiBaseUrl}/${ApiPath.WorkOrders}/phase/${key}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

async function getWorkOrderByIdAndPhase(workOrderId: string, phaseKey: string) {
  try {
    const response = await axios.get<WorkOrder>(`${apiBaseUrl}/${ApiPath.WorkOrders}/phase/${phaseKey}/${workOrderId}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

async function workOrderCommissionPhase(payload: { action: 'start' | 'stop' | 'end', phaseId: string }): Promise<void> {
  try {
    await axios.post(`${apiBaseUrl}/${ApiPath.WorkOrders}/commission-phase/${payload.phaseId}/${payload.action}`);
  } catch (err) {
    console.error(err);
  }
}

export function useGetWorkOrders() {
  return useQuery({
    queryKey: [QueryKey.GetWorkOrders],
    queryFn: getWorkOrders,
  });
}

export function useGetWorkOrderById(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetWorkOrderById, id],
    queryFn: () => getWorkOrderById(id),
    enabled: !!id,
  });
}

export function useCreateWorkOrder() {
  return useMutation({
    mutationKey: [QueryKey.CreateWorkOrder],
    mutationFn: createWorkOrder,
  });
}

export function useAddCommission() {
  return useMutation({
    mutationKey: [QueryKey.WorkOrderAddCommission],
    mutationFn: addCommission,
  });
}

export function useWocActivateAllWorkPhases() {
  return useMutation({
    mutationKey: [QueryKey.WocActivateAllWorkPhases],
    mutationFn: wocActivateAllWorkPhases,
  });
}

export function useWocUpdatePhase() {
  return useMutation({
    mutationKey: [QueryKey.WocUpdatePhase],
    mutationFn: wocUpdatePhase,
  });
}

export function useStartWorkOrder() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, string>({
    mutationKey: [QueryKey.StartWorkOrder],
    mutationFn: (id) => axios.put(`${apiBaseUrl}/${ApiPath.WorkOrders}/${id}/start`),
  });
}

export function useGetWorkOrderByPhaseKey(key: string) {
  return useQuery({
    queryKey: [QueryKey.GetWorkOrderByPhaseKey, key],
    queryFn: () => getWorkOrderByPhaseKey(key),
  });
}

export function useGetWorkOrderByIdAndPhase(workOrderId: string, phaseKey: string) {
  return useQuery({
    queryKey: [QueryKey.GetWorkOrderByIdAndPhase, workOrderId, phaseKey],
    queryFn: () => getWorkOrderByIdAndPhase(workOrderId, phaseKey),
  });
}

export function useWorkOrderCommissionPhase() {
  return useMutation({
    mutationKey: [QueryKey.WorkOrderCommissionPhase],
    mutationFn: workOrderCommissionPhase,
  });
}

export function useSimpleQuery<T>(key: string, url: string) {
  return useQuery({
    queryKey: [key],
    queryFn: () => simpleGet<T>(url),
  });
}

export const useGetCompletedWorkOrders = () => useSimpleQuery(QueryKey.GetCompletedWorkOrders, `${apiBaseUrl}/${ApiPath.WorkOrders}/completed`);

export const useGetWorkOrderPhaseCommission = (workPhaseKey: string, workOrderId: string, workOrderCommissionId: string) =>
  useSimpleQuery<WorkOrderCommission & { workOrder: { id: string, year: number, serial: number } }>(
    QueryKey.GetWorkOrderPhaseCommission,
    `${apiBaseUrl}/${ApiPath.WorkOrders}/phase/${workPhaseKey}/${workOrderId}/${workOrderCommissionId}`,
  );
