import { Referrer } from '@api/types/referrers';

export enum CustomerType {
  business = 'business',
  private = 'private',
}

export const paymentModes = [
  'on-withdraw',
  'bank-transfer-on-ready',
  'check-30-days',
  'riba',
  'mark',
  'tbd',
]

export interface Customer {
  id: string;
  type: CustomerType;
  referrerId?: string;

  referrer?: Referrer;

  // business fields
  name: string;
  vatNumber: string;
  sdi: string;

  // private fields
  firstName: string;
  lastName: string;
  taxCode: string;

  // common fields
  phoneNumber: string;
  email: string;
  pec: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;

  legalAddress: string;
  legalCity: string;
  legalProvince: string;
  legalPostalCode: string;
  legalCountry: string;

  paymentMode: 'on-withdraw' | 'bank-transfer-on-ready' | 'check-30-days' | 'riba' | 'mark' | 'tbd';
}
