import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useAppDispatch } from '@hooks/redux';
import { setAccessToken, setIsLoggedIn } from '@store/auth';
import { apiBaseUrl } from '@constants/constants';


export function useLogin() {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<boolean>();

  const login = async (username: string, password: string) => {
    try {
      setError(false);
      console.log('start login', username, password);
      const response = await axios.post(`${apiBaseUrl}/auth/login`, { username, password });

      console.log('access token', response.data.access_token);

      dispatch(setAccessToken(response.data.access_token));
      dispatch(setIsLoggedIn(true));
    } catch (e) {
      if (e instanceof AxiosError) {
        console.error('axios error', JSON.stringify(e));
      }

      console.error(e);
      setError(true);
    }
  };

  return {
    login,
    error,
  };
}
