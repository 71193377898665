import { Button, Card, Col, Grid, Modal, Row, Typography } from 'antd';
import { useCheckIn, useCheckOut, useGetDailyCheck, useTimeBreakEnd, useTimeBreakStart } from '@api/UsersApi';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { TimeZone } from '@constants/constants';

export function Dashboard() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [isOpenCheckIn, setIsOpenCheckIn] = useState(false);
  const [isOpenCheckOut, setIsOpenCheckOut] = useState(false);
  const [isOpenTimeBreakStart, setIsOpenTimeBreakStart] = useState(false);
  const [isOpenTimeBreakEnd, setIsOpenTimeBreakEnd] = useState(false);

  const { data: dailyCheck, refetch: refetchDailyCheck } = useGetDailyCheck();

  const { mutate: checkIn, isSuccess: isSuccessCheckIn, reset: resetCheckIn } = useCheckIn();
  const { mutate: checkOut, isSuccess: isSuccessCheckOut, reset: resetCheckOut } = useCheckOut();

  const {
    mutate: timeBreakStart,
    isSuccess: isSuccessTimeBreakStart,
    reset: resetTimeBreakStart,
  } = useTimeBreakStart();
  const { mutate: timeBreakEnd, isSuccess: isSuccessTimeBreakEnd, reset: resetTimeBreakEnd } = useTimeBreakEnd();

  console.log('dailyCheck', dailyCheck);

  const onCheckInClick = () => {
    setIsOpenCheckIn(true);
  };

  const onCheckInOk = () => {
    setIsOpenCheckIn(false);
    checkIn();
  };

  const onCheckInCancel = () => {
    setIsOpenCheckIn(false);
  };

  const onCheckOutClick = () => {
    setIsOpenCheckOut(true);
  };

  const onCheckOutOk = () => {
    setIsOpenCheckOut(false);
    checkOut();
  };

  const onCheckOutCancel = () => {
    setIsOpenCheckOut(false);
  };

  const onTimeBreakStartClick = () => {
    setIsOpenTimeBreakStart(true);
  };

  const onTimeBreakStartOk = () => {
    setIsOpenTimeBreakStart(false);
    timeBreakStart();
  };

  const onTimeBreakStartCancel = () => {
    setIsOpenTimeBreakStart(false);
  };

  const onTimeBreakEndClick = () => {
    setIsOpenTimeBreakEnd(true);
  };

  const onTimeBreakEndOk = () => {
    setIsOpenTimeBreakEnd(false);
    timeBreakEnd();
  };

  const onTimeBreakEndCancel = () => {
    setIsOpenTimeBreakEnd(false);
  };

  useEffect(() => {
    if (isSuccessCheckIn) {
      resetCheckIn();
      refetchDailyCheck();
      setIsOpenCheckIn(false);
    }
  }, [isSuccessCheckIn]);

  useEffect(() => {
    if (isSuccessCheckOut) {
      resetCheckOut();
      refetchDailyCheck();
      setIsOpenCheckOut(false);
    }
  }, [isSuccessCheckOut]);

  useEffect(() => {
    if (isSuccessTimeBreakStart) {
      resetTimeBreakStart();
      refetchDailyCheck();
      setIsOpenTimeBreakStart(false);
    }
  }, [isSuccessTimeBreakStart]);

  useEffect(() => {
    if (isSuccessTimeBreakEnd) {
      resetTimeBreakEnd();
      refetchDailyCheck();
      setIsOpenTimeBreakEnd(false);
    }
  }, [isSuccessTimeBreakEnd]);

  return (
    <Row gutter={16}>
      <Col span={24} lg={12}>
        <Card>
          <Typography.Title level={3} style={{ marginTop: 0 }}>Presenza</Typography.Title>

          <Row>
            <Col span={24} xl={12} style={{ marginBottom: 18 }}>
              <Typography>Data: {format(new Date(), 'dd-MM-yyyy')}</Typography>
              <Typography>Ingresso: {dailyCheck?.timeIn ? formatInTimeZone(dailyCheck.timeIn + 'Z', TimeZone, 'HH:mm') : ''}</Typography>
              <Typography>Uscita: {dailyCheck?.timeOut ? format(dailyCheck.timeOut + 'Z', 'HH:mm') : ''}</Typography>
              <Typography>Tempo: {dailyCheck?.elapsedTime ?? ''}</Typography>
            </Col>

            <Col span={24} xl={12}>
              {dailyCheck?.checkedIn === false && dailyCheck?.checkedOut === false && (
                <Button
                  type="primary"
                  ghost
                  style={{ width: '100%', height: 66, fontSize: 24 }}
                  onClick={onCheckInClick}
                >Registra Entrata</Button>
              )}

              {dailyCheck?.checkedIn === true && (
                <Button
                  type="primary"
                  ghost
                  style={{ width: '100%', height: 66, fontSize: 24 }}
                  onClick={onCheckOutClick}
                  disabled={dailyCheck.isOnBreak || dailyCheck?.checkedOut === true}
                >Registra Uscita</Button>
              )}
            </Col>

          </Row>

          <Typography.Title level={3}>Pause</Typography.Title>

          <Row>
            <Col span={24} xl={12} style={{ marginBottom: 18 }}>
              <Typography>Pause Odierne: {dailyCheck?.timeBreaksCount ?? 0}</Typography>
              <Typography>Minuti Totali: {dailyCheck?.totalBreakTime ?? 0}</Typography>
            </Col>

            <Col span={24} xl={12}>
              {dailyCheck?.isOnBreak === false && (
                <Button
                  type="primary"
                  ghost
                  style={{ width: '100%', height: 66, fontSize: 24 }}
                  onClick={onTimeBreakStartClick}
                  disabled={dailyCheck?.checkedIn === false || dailyCheck?.checkedOut === true}
                >Inizia Pausa</Button>
              )}

              {dailyCheck?.checkedIn === true && dailyCheck?.isOnBreak === true && (
                <Button
                  type="primary"
                  ghost
                  style={{ width: '100%', height: 66, fontSize: 24 }}
                  onClick={onTimeBreakEndClick}
                  disabled={dailyCheck?.checkedOut === true}
                >Termina Pausa</Button>
              )}
            </Col>
          </Row>
        </Card>
      </Col>

      <Modal title="Conferma entrata" open={isOpenCheckIn} onOk={onCheckInOk} onCancel={onCheckInCancel}>
        <Typography>Per favore conferma la registrazione dell'entrata</Typography>
      </Modal>

      <Modal title="Conferma entrata" open={isOpenCheckOut} onOk={onCheckOutOk} onCancel={onCheckOutCancel}>
        <Typography>Per favore conferma la registrazione dell'uscita</Typography>
      </Modal>

      <Modal
        title="Conferma inizio pausa"
        open={isOpenTimeBreakStart}
        onOk={onTimeBreakStartOk}
        onCancel={onTimeBreakStartCancel}
      >
        <Typography>Per favore conferma l'inizio della pausa</Typography>
      </Modal>

      <Modal
        title="Conferma termine pausa"
        open={isOpenTimeBreakEnd}
        onOk={onTimeBreakEndOk}
        onCancel={onTimeBreakEndCancel}
      >
        <Typography>Per favore conferma il termine della pausa</Typography>
      </Modal>
    </Row>
  );
}
